import React from "react";
import { Box, Typography, styled } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import StarIcon from "@mui/icons-material/Star";

const MainBox = styled(Box)`
  margin-top: 35px;
  padding: 25px;
`;

const Welcometype = styled(Typography)(({ theme }) => ({
  color: "#29398d",
  fontSize: "32px",
  lineHeight: "60px",
  fontWeight: "600",
  textTransform: "none",
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-140px",
    fontSize: "18px",
  },
}));

const SideBox = styled(Box)(({ theme }) => ({
  width: "70%",
  marginTop: "45px",
  marginLeft: "210px",
  background: "LightGray",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: "0px",
    marginTop: "10px",
  },
}));

const ReviewMainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  margin: "25px",
  [theme.breakpoints.down("lg")]: {
    display: "block",
    marginLeft: "36px",
  },
}));

const WeidthBox = styled(Box)(({ theme }) => ({
  width: "350px",
  height: "300px",
  background: "white",
  margin: "35px",
  boxShadow: "0.3em 0.3em 1em rgba(0, 0, 0, 0.3)",
  [theme.breakpoints.down("lg")]: {
    width: "250px",
    height: "300px",
    background: "white",
    margin: "9px",
  },
}));

const GoogleFontStyleIcon = styled(Box)(({ theme }) => ({
  marginLeft: "110px",
  marginTop: "15px",
  color: "red",
  [theme.breakpoints.down("lg")]: {
    marginTop: "0px",
  },
}));

const ReviewText = styled(Box)`
  margin-left: 15px;
  margin-top: -15px;
  color: #777 !important;
  line-height: 24px !important;
  font-size: 14px !important;
  font: inherit !important;
  vertical-align: baseline !important;
`;

const Five = () => {
  return (
    <React.Fragment>
      <MainBox>
        <Box>
          <Welcometype>What Our Customers Have to Say</Welcometype>
        </Box>

        <SideBox>
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <Box>
                  <ReviewMainBox>
                    <WeidthBox>
                      <GoogleFontStyleIcon>
                        <GoogleIcon style={{ fontSize: "30px" }} />
                      </GoogleFontStyleIcon>
                      <ReviewText>
                        <Box>
                          <FormatQuoteIcon
                            style={{ color: "LightGray", fontSize: "30px" }}
                          />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          Truckifix was great he was nice, easy going he
                          replaced my car keys for a low price i would
                          definitely recommend him to others and most definitely
                          be calling him again in the future if i have any
                          problems.
                        </Box>
                      </ReviewText>
                    </WeidthBox>

                    <WeidthBox>
                      <GoogleFontStyleIcon>
                        <GoogleIcon style={{ fontSize: "30px" }} />
                      </GoogleFontStyleIcon>
                      <ReviewText>
                        <Box>
                          <FormatQuoteIcon
                            style={{ color: "LightGray", fontSize: "30px" }}
                          />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          Truckifix was the nicest most down to earth guy i've
                          ever seen working at 1:00AM, Great Prices, Great
                          response time, Great guy to be around. if there a way
                          into the lock you need into, he,s the guy i'd call.
                          i'll definitely call him out again if anything
                          happens.
                        </Box>
                      </ReviewText>
                    </WeidthBox>

                    <WeidthBox>
                      <GoogleFontStyleIcon>
                        <GoogleIcon style={{ fontSize: "30px" }} />
                      </GoogleFontStyleIcon>
                      <ReviewText>
                        <Box>
                          <FormatQuoteIcon
                            style={{ color: "LightGray", fontSize: "30px" }}
                          />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          Truckifix did a fantastic job. They cut a key for me
                          on the weekend at a reasonable rate. i look forwrad to
                          doing bussiness with mike in future. 10/10
                        </Box>
                      </ReviewText>
                    </WeidthBox>
                  </ReviewMainBox>
                </Box>
              </div>
              <div className="carousel-item">
                <Box>
                  <ReviewMainBox>
                    <WeidthBox>
                      <GoogleFontStyleIcon>
                        <GoogleIcon style={{ fontSize: "30px" }} />
                      </GoogleFontStyleIcon>
                      <ReviewText>
                        <Box>
                          <FormatQuoteIcon
                            style={{ color: "LightGray", fontSize: "30px" }}
                          />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          Mike was prompt to answer my call for help with a dead
                          battery. Cost was good will definitely use again and
                          will be sure family members have his number.
                        </Box>
                      </ReviewText>
                    </WeidthBox>

                    <WeidthBox>
                      <GoogleFontStyleIcon>
                        <GoogleIcon style={{ fontSize: "30px" }} />
                      </GoogleFontStyleIcon>
                      <ReviewText>
                        <Box>
                          <FormatQuoteIcon
                            style={{ color: "LightGray", fontSize: "30px" }}
                          />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          THEY WERE ON TIME, VERY COURTEOUS AND COMPLETED THE
                          JOB IN A TIMELY MANNER.
                        </Box>
                      </ReviewText>
                    </WeidthBox>

                    <WeidthBox>
                      <GoogleFontStyleIcon>
                        <GoogleIcon style={{ fontSize: "30px" }} />
                      </GoogleFontStyleIcon>
                      <ReviewText>
                        <Box>
                          <FormatQuoteIcon
                            style={{ color: "LightGray", fontSize: "30px" }}
                          />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          Got here fast, got my keys out of my car was a berry
                          nice guy will use again!!
                        </Box>
                      </ReviewText>
                    </WeidthBox>
                  </ReviewMainBox>
                </Box>
              </div>
              <div className="carousel-item">
                <Box>
                  <ReviewMainBox>
                    <WeidthBox>
                      <GoogleFontStyleIcon>
                        <GoogleIcon style={{ fontSize: "30px" }} />
                      </GoogleFontStyleIcon>
                      <ReviewText>
                        <Box>
                          <FormatQuoteIcon
                            style={{ color: "LightGray", fontSize: "30px" }}
                          />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          11/10 , super friendly and helpful! Great prices !!
                          Definitely my go to guy from now on
                        </Box>
                      </ReviewText>
                    </WeidthBox>

                    <WeidthBox>
                      <GoogleFontStyleIcon>
                        <GoogleIcon style={{ fontSize: "30px" }} />
                      </GoogleFontStyleIcon>
                      <ReviewText>
                        <Box>
                          <FormatQuoteIcon
                            style={{ color: "LightGray", fontSize: "30px" }}
                          />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          Nice guy, very informative and is really good at what
                          he does. Will definitely recommend to anybody who
                          needs a locksmith!
                        </Box>
                      </ReviewText>
                    </WeidthBox>

                    <WeidthBox>
                      <GoogleFontStyleIcon>
                        <GoogleIcon style={{ fontSize: "30px" }} />
                      </GoogleFontStyleIcon>
                      <ReviewText>
                        <Box>
                          <FormatQuoteIcon
                            style={{ color: "LightGray", fontSize: "30px" }}
                          />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          Truckifix Sprague was a great help and was also nice
                          and polite!
                        </Box>
                      </ReviewText>
                    </WeidthBox>
                  </ReviewMainBox>
                </Box>
              </div>
              <div className="carousel-item">
                <Box>
                  <ReviewMainBox>
                    <WeidthBox>
                      <GoogleFontStyleIcon>
                        <GoogleIcon style={{ fontSize: "30px" }} />
                      </GoogleFontStyleIcon>
                      <ReviewText>
                        <Box>
                          <FormatQuoteIcon
                            style={{ color: "LightGray", fontSize: "30px" }}
                          />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          Great Fast Service
                        </Box>
                      </ReviewText>
                    </WeidthBox>

                    <WeidthBox>
                      <GoogleFontStyleIcon>
                        <GoogleIcon style={{ fontSize: "30px" }} />
                      </GoogleFontStyleIcon>
                      <ReviewText>
                        <Box>
                          <FormatQuoteIcon
                            style={{ color: "LightGray", fontSize: "30px" }}
                          />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          Truckifix was great, he was nice, easy going he
                          replaced my car keys for a low price I would
                          definitely recommend him to others and most definitely
                          be calling him again in the future if I have any
                          problems.
                        </Box>
                      </ReviewText>
                    </WeidthBox>

                    <WeidthBox>
                      <GoogleFontStyleIcon>
                        <GoogleIcon style={{ fontSize: "30px" }} />
                      </GoogleFontStyleIcon>
                      <ReviewText>
                        <Box>
                          <FormatQuoteIcon
                            style={{ color: "LightGray", fontSize: "30px" }}
                          />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          <StarIcon style={{ color: "rgb(231, 113, 27)" }} />
                          Truckifix was the nicest most down to earth guy I’ve
                          ever seen working at 1:00AM, Great prices, Great
                          response time, Great guy to be around. If there’s a
                          way into the lock you need into, he’s the guy I’d
                          call. I’ll definitely call him out again if anything
                          happens.
                        </Box>
                      </ReviewText>
                    </WeidthBox>
                  </ReviewMainBox>
                </Box>
              </div>
            </div>
            <a
              className="carousel-control-prev "
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </SideBox>
      </MainBox>
    </React.Fragment>
  );
};

export default Five;
