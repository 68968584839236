import React from "react";
import House from "../Header/House";
import { Box, styled, Typography } from "@mui/material";

const MainBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
  backgroundRepeat: "no-repeat, repeat",
  height: "400px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  color: "#fff",
  backgroundAttachment: "fixed",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-100px",
    display: "block",
    height: "100px",
  },
}));

const TextMain = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: "54px",
  lineHeight: "1.2",
  fontWeight: "600",
  textAlign: "center",
  marginLeft: "405px",
  marginTop: "150px",
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
    marginLeft: "-15px",
    marginTop: "100px",
    fontSize: "30px",
    padding: "26px",
  },
}));

const AtTextFild = styled(Typography)(({ theme }) => ({
  color: "#29398d",
  fontSize: "32px",
  lineHeight: "60px",
  fontWeight: "600",
  textTransform: "none",
  marginTop: "50px",
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    fontSize: "22px",
    marginTop: "10px",
  },
}));

const TheTextFild = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  lineHeight: "20px",
  fontWeight: "600",
  textTransform: "none",
  marginTop: "0px",
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    fontSize: "22px",
    padding: "5px",
  },
}));

const SecondMainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("lg")]: {
    display: "block",
  },
}));

const WeGotRidTextFild = styled(Typography)(({ theme }) => ({
  color: "#454545",
  fontSize: "20px",
  fontWeight: "500",
  width: "30%",
  marginTop: "58px",
  marginLeft: "190px",
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    fontSize: "15px",
    marginLeft: "22px",
    marginTop: "-20px",
  },
}));

const ImageWeGotImage = styled("img")(({ theme }) => ({
  border: "1px solid green",
  width: "300px",
  height: "200px",
  marginLeft: "390px",
  marginBottom: "50px",
  marginTop: "40px",
  [theme.breakpoints.down("lg")]: {
    width: "300px",
    height: "200px",
    marginLeft: "40px",
  },
}));

const MissionText = styled(Typography)(({ theme }) => ({
  color: "#29398d",
  fontSize: "32px",
  lineHeight: "60px",
  fontWeight: "600",
  textTransform: "none",
  marginTop: "10px",
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    fontSize: "22px",
  },
}));

const ToCreateTextFild = styled(Typography)(({ theme }) => ({
  color: "#454545",
  textAlign: "center",
  fontSize: "16px",
  lineHeight: "28px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "13px",
    marginTop: "-10px",
    padding: "5px",
  },
}));

const ThridBox = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "380px",
  background: "LightGray",
  marginTop: "45px",
  marginLeft: "380px",
  marginBottom: "55px",
  [theme.breakpoints.down("lg")]: {
    height: "480px",
    marginLeft: "18px",
    width: "90%",
  },
}));

const QueqText = styled(Typography)(({ theme }) => ({
  color: "#29398d",
  textAlign: "center",
  padding: "20px",
  fontSize: "18px",
  fontWeight: "600",
  lineHeight: "28px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "15px",
  },
}));

const Blog = () => {
  return (
    <>
      <House />
      <MainBox>
        <Box>
          <TextMain>“Your Sefty Is Our Priority”</TextMain>
        </Box>
      </MainBox>
      <Box>
        <AtTextFild>At Truckifix We Believe</AtTextFild>
        <TheTextFild>
          the roadside assistance industry can be improved
        </TheTextFild>
        <TheTextFild>by making it on-demand and automated.</TheTextFild>
      </Box>
      <Box>
        <SecondMainBox>
          <ImageWeGotImage src="Image/About.jpeg" alt="" />
          <WeGotRidTextFild>
            We got rid of subscriptions and annual membership fees and the
            manual dispatch process where someone haggles the price to come help
            you. your sefty is our priority.” And we believe that there are good
            people in the world that are truly willing and want to help others.
            This is the foundation that our company is built on.
          </WeGotRidTextFild>
        </SecondMainBox>
      </Box>

      <Box>
        <MissionText>Mission:</MissionText>
        <ToCreateTextFild>
          To create and maintain an on-demand roadside assistance app that
          connects{" "}
        </ToCreateTextFild>
        <ToCreateTextFild>
          customers with service providers through an automated system of
          dispatch
        </ToCreateTextFild>
        <ToCreateTextFild> and service.</ToCreateTextFild>
      </Box>

      <Box>
        <MissionText>About Us </MissionText>
        <ToCreateTextFild>
          We got away from subscriptions and annual membership fees and the
          manual{" "}
        </ToCreateTextFild>
        <ToCreateTextFild>
          dispatch process where someone haggles the price to come help you.{" "}
        </ToCreateTextFild>
        <ToCreateTextFild>
          Truckifix believes that roadside assistance has improved by{" "}
        </ToCreateTextFild>
        <ToCreateTextFild>
          making it On-demand and Automated through technology.
        </ToCreateTextFild>
      </Box>

      <Box>
        <ThridBox>
          <QueqText>Quick, Reliable Service</QueqText>
          <ToCreateTextFild>
            When Truckifix’s network service providers accept jobs, They make
            full preparations{" "}
          </ToCreateTextFild>
          <ToCreateTextFild>
            to make sure the customer is happy the first time!
          </ToCreateTextFild>

          <QueqText>Dependable Roadside Service Technicians</QueqText>
          <ToCreateTextFild>
            All of Truckifix’s network service provider technicians are drug
            tested and pass a{" "}
          </ToCreateTextFild>
          <ToCreateTextFild>background check.</ToCreateTextFild>

          <QueqText>Customer Satisfaction Guaranteed</QueqText>
          <ToCreateTextFild>
            Reliable, Trustworthy, Reasonable Pricing On-Demand Network
          </ToCreateTextFild>
        </ThridBox>
      </Box>
    </>
  );
};

export default Blog;
