import React from "react";

import { styled, Box, Typography } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import MapIcon from "@mui/icons-material/Map";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import CampaignIcon from "@mui/icons-material/Campaign";

const MainBox = styled(Box)(({ theme }) => ({
  background: "#d6d6d6",
  padding: "25px",
  [theme.breakpoints.down("lg")]: {
    background: "#fff",
    padding: "25px",
    marginLeft: "13px",
  },
}));

const Welcometype = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: "600",
  color: "#29398d",
  textAlign: "center",
  letterSpacing: "3px;",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-140px",
    fontSize: "22px",
    fontWeight: "600",
    color: "#29398d",
    textAlign: "center",
    letterSpacing: "0px;",
  },
}));

const MachText = styled(Typography)(({ theme }) => ({
  color: "black",
  marginTop: "5px",
  fontSize: "16px",
  fontWeight: "500",
  textAlign: "center",
  lineHeight: "28px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "14px",
    fontWeight: "600",
  },
}));

const SecBox = styled(Box)`
  margin-top: 45px;
`;

const ThirdBox = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "50px",
  marginLeft: "30px",
  [theme.breakpoints.down("lg")]: {
    display: "block",
    padding: "50px",
    marginLeft: "30px",
  },
}));

const GspBox = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: "10px",
  marginLeft: "40px",
  color: "#000000",
  minHeight: "50px",
  marginBottom: "15px",
  fontSize: "19px",
  lineHeight: "24px",
  fontWeight: "600",
  fontFamily: "'Raleway', sans-serif",
  [theme.breakpoints.down("lg")]: {
    marginTop: "10px",
    marginLeft: "-18px",
    color: "#000000",

    fontSize: "12px",

    fontWeight: "600",
    fontFamily: "'Raleway', sans-serif",
  },
}));

const GspBoxTwo = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: "10px",
  marginLeft: "40px",
  color: "#000000",
  minHeight: "50px",
  marginBottom: "15px",
  fontSize: "19px",
  lineHeight: "24px",
  fontWeight: "600",
  fontFamily: "'Raleway', sans-serif",
  [theme.breakpoints.down("lg")]: {
    marginTop: "10px",
    marginLeft: "-5px",
    color: "#000000",

    fontSize: "12px",

    fontWeight: "600",
    fontFamily: "'Raleway', sans-serif",
  },
}));

const MainGspBox = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  marginLeft: "40px",
  color: "#000000",
  minHeight: "50px",
  marginBottom: "15px",
  fontSize: "19px",
  lineHeight: "24px",
  fontWeight: "600",
  fontFamily: "'Raleway', sans-serif",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-15px",
    marginLeft: "-10px",
    padding: "20px",
    color: "#000000",

    fontSize: "12px",

    fontWeight: "600",
    fontFamily: "'Raleway', sans-serif",
  },
}));

const WeUseText = styled(Typography)(({ theme }) => ({
  margin: "15px",
  fontSize: "16px",
  lineHeight: "28px",
  color: "#787878",
  fontFamily: "'Open Sans', sans-serif",
  [theme.breakpoints.down("lg")]: {
    width: "320px",
    marginLeft: "-70px",
    marginTop: "-35px",
  },
}));

const PublicBoxIcon = styled(Box)(({ theme }) => ({
  color: "rgb(41, 57, 141)",
  marginLeft: "10rem",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "3rem",
    marginTop: "-30px",
  },
}));

const MapBox = styled(Box)(({ theme }) => ({
  color: "rgb(41, 57, 141)",
  marginLeft: "8rem",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "3rem",
    marginTop: "20px",
  },
}));

const MasageBoxIcon = styled(Box)(({ theme }) => ({
  color: "rgb(41, 57, 141)",
  marginLeft: "8rem",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "3rem",
    marginTop: "20px",
  },
}));

const CompionIconBox = styled(Box)(({ theme }) => ({
  color: "rgb(41, 57, 141)",
  marginLeft: "8rem",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "3rem",
    marginTop: "20px",
  },
}));

const Third = () => {
  return (
    <React.Fragment>
      <MainBox>
        <SecBox>
          <Welcometype>Truckifix Services at a Glance</Welcometype>
          <MachText>The On-Demand Roadside Assistance App.</MachText>
        </SecBox>
        <ThirdBox>
          <Box>
            <PublicBoxIcon>
              <PublicIcon style={{ fontSize: "3rem" }} />
            </PublicBoxIcon>
            <GspBox>GPS LOCATION ASSISTANCE</GspBox>

            <WeUseText>
              We use the latest GPS and mapping technology to get you help fast.
              No more trying to figure out where you are and describing your
              location.
            </WeUseText>
          </Box>
          <Box>
            <MapBox>
              <MapIcon style={{ fontSize: "3rem" }} />
            </MapBox>

            <MainGspBox>NO MEMBERSHIP ANY</MainGspBox>
            <WeUseText>
              Only pay for help when you need it. The app gives you access to
              our network of vetted providers, ready.
            </WeUseText>
          </Box>

          <Box>
            <MasageBoxIcon>
              <MapsUgcIcon style={{ fontSize: "3rem" }} />
            </MasageBoxIcon>

            <GspBoxTwo>IN-APP COMMUNICATION</GspBoxTwo>
            <WeUseText>
              Comprehensive Service Options Choose from a range of services
              including towing, tire changes, battery jumps, fuel delivery, and
              lockout assistance.
            </WeUseText>
          </Box>

          <Box>
            <CompionIconBox>
              <CampaignIcon style={{ fontSize: "3rem" }} />
            </CompionIconBox>

            <GspBoxTwo>AUTOMATED DISPATCH</GspBoxTwo>
            <WeUseText>
              Our app finds the closest provider to you without the hassle of
              someone calling and haggling a price for you.
            </WeUseText>
          </Box>
        </ThirdBox>
      </MainBox>
    </React.Fragment>
  );
};

export default Third;
