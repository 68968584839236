import { styled, Box, Typography } from "@mui/material";
import React from "react";

import "react-multi-carousel/lib/styles.css";

const MainClass = styled(Box)(({ theme }) => ({
  marginLeft: "30px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0px",
  },
}));

const Welcometype = styled(Typography)(({ theme }) => ({
  marginTop: "60px",
  fontSize: "30px",
  fontWeight: "600",
  color: "#29398d",
  textAlign: "center",
  letterSpacing: "3px",
  [theme.breakpoints.down("lg")]: {
    letterSpacing: "0px",
    fontSize: "20px",
    marginTop: "25px",
  },
}));

const MachText = styled(Typography)(({ theme }) => ({
  color: "#787878;",
  marginTop: "5px",
  fontSize: "16px",
  fontWeight: "500",
  textAlign: "center",
  lineHeight: "28px",
  [theme.breakpoints.down("lg")]: {
    color: "#787878;",
    padding: "6px",
    marginTop: "0px",
    marginRight: "5px",
    fontSize: "12px",
    fontWeight: "600",
    textAlign: "center",
    lineHeight: "15px",
  },
}));

const CompanyText = styled(Typography)(({ theme }) => ({
  color: "black",
  fontSize: "16px",
  fontWeight: "500",
  textAlign: "center",
  lineHeight: "28px",
  [theme.breakpoints.down("lg")]: {
    color: "#787878;",
    padding: "6px",
    marginTop: "-18px",
    marginRight: "10px",
    fontSize: "13px",
    fontWeight: "600",
    textAlign: "center",
    lineHeight: "28px",
  },
}));

const SeconMain = styled(Box)(({ theme }) => ({
  display: "flex",
  marginLeft: "300px",
  marginRight: "300px",
  marginTop: "20px",
  marginBottom: "100px",
  [theme.breakpoints.down("lg")]: {
    display: "block",
    marginLeft: "300px",
    marginRight: "300px",
    marginTop: "20px",
    marginBottom: "100px",
  },
}));

const DeadText = styled(Typography)(({ theme }) => ({
  marginTop: "10px",
  cursor: "pointer",
  fontSize: "19px",
  lineHeight: "28px",
  fontWeight: "500",
  color: "#303030",
  [theme.breakpoints.down("lg")]: {
    cursor: "pointer",
    fontSize: "15px",
    marginTop: "-10px",
    fontWeight: "600",
    color: "#303030",
    marginLeft: "-170px",
  },
}));

const DeadTextSec = styled(Typography)(({ theme }) => ({
  marginTop: "10px",
  cursor: "pointer",
  fontSize: "19px",
  lineHeight: "28px",
  fontWeight: "500",
  color: "#303030",
  [theme.breakpoints.down("lg")]: {
    cursor: "pointer",
    fontSize: "15px",
    marginTop: "-10px",
    fontWeight: "600",
    color: "#303030",
    marginLeft: "-180px",
  },
}));

const MettarText = styled(Typography)(({ theme }) => ({
  marginTop: "12px",
  color: "#454545",
  fontSize: "16px",
  lineHeight: "25px",
  padding: "0px",
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    marginTop: "8px",
    lineHeight: "20px",
    color: "#454545",
    fontSize: "16px",
    marginLeft: "-190px",
    paddingLeft: "30px",
  },
}));

const MettarTextSec = styled(Typography)(({ theme }) => ({
  marginTop: "12px",
  color: "#454545",
  fontSize: "16px",
  lineHeight: "25px",
  padding: "0px",
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    marginTop: "8px",
    lineHeight: "20px",
    color: "#454545",
    fontSize: "16px",
    marginLeft: "-235px",
    paddingLeft: "30px",
    textAlign: "center",
  },
}));

const FirstText = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  marginTop: "25px",
  fontSize: "19px",
  lineHeight: "24px",
  fontWeight: "500",
  color: "#303030",
  [theme.breakpoints.down("lg")]: {
    cursor: "pointer",
    marginTop: "20px",
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: "600",
    color: "#303030",
    marginLeft: "-190px",
    paddingLeft: "30px",
  },
}));

const NonEmText = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  fontSize: "19px",
  lineHeight: "24px",
  fontWeight: "500",
  color: "#303030",
  [theme.breakpoints.down("lg")]: {
    cursor: "pointer",
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: "600",
    color: "#303030",
    marginLeft: "-230px",
    paddingLeft: "30px",
    marginTop: "25px",
  },
}));

const TowText = styled(Typography)(({ theme }) => ({
  marginTop: "25px",
  fontSize: "19px",
  lineHeight: "24px",
  fontWeight: "500",
  color: "#303030",
  cursor: "pointer",
  [theme.breakpoints.down("lg")]: {
    marginTop: "15px",
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: "600",
    color: "#303030",
    cursor: "pointer",
    marginLeft: "-210px",
    paddingLeft: "30px",
  },
}));

const TripText = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  marginTop: "50px",
  fontSize: "19px",
  lineHeight: "24px",
  fontWeight: "500",
  color: "#303030",
  [theme.breakpoints.down("lg")]: {
    cursor: "pointer",
    marginTop: "20px",
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: "600",
    color: "#303030",
    marginLeft: "-230px",
    paddingLeft: "30px",
  },
}));

const SeconBox = styled(Box)(({ theme }) => ({
  margin: "25px",
}));

const ImageNew = styled("img")(({ theme }) => ({
  width: "250px",
  height: "510px",

  [theme.breakpoints.down("lg")]: {
    width: "100px",
    height: "200px",
    marginLeft: "-150px",
    marginTop: "25px",
    overflow: "hidden",
  },
}));

const NewTextBox = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    width: "160px",
    marginLeft: "-110px",
    fontWeight: "400",
  },
}));

const FirstTextLast = styled(Box)(({ theme }) => ({
  width: "100%",
  cursor: "pointer",
  marginTop: "25px",
  fontSize: "19px",
  lineHeight: "24px",
  fontWeight: "500",
  color: "#303030",
  [theme.breakpoints.down("lg")]: {
    width: "250px",
    marginLeft: "-220px",
    paddingLeft: "30px",
    cursor: "pointer",
    marginTop: "20px",
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: "600",
    color: "#303030",
  },
}));

const NewTextAlign = styled(Typography)(({ theme }) => ({
  marginTop: "12px",
  color: "#454545",
  fontSize: "16px",
  lineHeight: "28px",

  [theme.breakpoints.down("lg")]: {
    marginTop: "8px",
    color: "#454545",
    fontSize: "16px",
    marginLeft: "-200px",
    pading: "10px",
    textAlign: "center",
  },
}));

const SecondHome = () => {
  return (
    <React.Fragment>
      <MainClass>
        <Box>
          <Welcometype>Welcome to Truckifix</Welcometype>
          <MachText>
            Truckifix Services is the first of its kind, on-demand roadside{" "}
          </MachText>
          <CompanyText>
            company that automatically dispatches help when you need it!
          </CompanyText>
        </Box>
        <SeconMain>
          <Box>
            <DeadText>DEAD BATTERY</DeadText>
            <NewTextBox>
              <MettarText>
                No matter where you are, getting moving again is easy with
                Truckifix….
              </MettarText>
            </NewTextBox>

            <FirstText>FLAT TIRE</FirstText>
            <NewTextBox>
              <MettarText>
                We make getting roadside easier than ever with our and automated
                process…
              </MettarText>
            </NewTextBox>

            <FirstTextLast>MOBILE MECHANIC</FirstTextLast>
            <NewTextBox>
              <MettarText>
                We vet and background check all service providers so you can tap
                into a...
              </MettarText>
            </NewTextBox>

            <FirstText>LOCKOUTS</FirstText>
            <NewTextBox>
              <MettarText>
                We understand the sense of urgency that comes with being locked
                at Truckifix…
              </MettarText>
            </NewTextBox>
          </Box>
          <Box>
            <ImageNew src="Image/89354.png" alt="" />
          </Box>
          <SeconBox>
            <DeadTextSec>OUT OF FUEL</DeadTextSec>
            <NewTextBox>
              <MettarTextSec>
                With Truckifix we help get you moving fast and at fair market
                prices, so you’ll…
              </MettarTextSec>
            </NewTextBox>

            <NonEmText>NON-EMERGENCY</NonEmText>
            <NewTextBox>
              <NewTextAlign>
                You can use our free service that non-emergency police
                department…
              </NewTextAlign>
            </NewTextBox>

            <TowText>TOW TRUCK</TowText>
            <NewTextBox>
              <NewTextAlign>
                We can get you a tow truck fast with our on-demand and automated
                app….
              </NewTextAlign>
            </NewTextBox>

            <TripText>TRIP INSPECTION</TripText>
            <NewTextBox>
              <MettarText>
                Order a trip inspection at work or home, where ever it’s
                convenient for you and…
              </MettarText>
            </NewTextBox>
          </SeconBox>
        </SeconMain>
      </MainClass>
    </React.Fragment>
  );
};

export default SecondHome;
