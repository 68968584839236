import React from "react";
import { styled, Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const MainBoxSec = styled(Box)`
  margin-top: 50px;
  padding: 25px;
  margin-bottom: 50px;
`;

const WelcomeType = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: "600",
  color: "#29398d",
  textAlign: "center",
  letterSpacing: "3px",
  marginButtom: "35px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
    marginTop: "-50px",
    marginLeft: "7px",
  },
}));

const ThirdBox = styled(Box)(({ theme }) => ({
  marginLeft: "180px",
  marginRight: "180px",
  marginTop: "30px",
  marginButtom: "30px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "9px",
    marginRight: "0px",
    marginTop: "30px",
    marginButtom: "0px",
  },
}));

const HowText = styled(Typography)(({ theme }) => ({
  fontSize: "25px",
  position: "relative",
  display: "table-cell",
  verticalAlign: "middle",
  textAlign: "left",
  padding: "5px 0",
  [theme.breakpoints.down("lg")]: {
    fontSize: "17px",
    fontWeight: "600",
  },
}));

const PriceText = styled(Typography)(({ theme }) => ({
  paddingLeft: "20px",
  width: "100%",
  color: "#454545",
  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
    fontWeight: "500",
  },
}));

const FlatSecond = () => {
  return (
    <MainBoxSec>
      <Box>
        <WelcomeType>Frequently Asked Questions</WelcomeType>
      </Box>
      <ThirdBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <HowText>How much will it cost?</HowText>
          </AccordionSummary>
          <AccordionDetails>
            <PriceText>
              Costs fluctuate in light of district. You will see an expected
              cost in the application before you affirm your administration.
              When your Specialist organization assists you, you'll with signing
              off on the last sum from their gadget. Costs depend on honest
              evaluation, meaning the normal cost of each assistance in the
              locale.
            </PriceText>
          </AccordionDetails>
        </Accordion>
      </ThirdBox>
      <ThirdBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <HowText>Do I need to be a member?</HowText>
          </AccordionSummary>
          <AccordionDetails>
            <PriceText>
              No, with Truckifix you essentially pay for emergency aides when
              you really want it.
            </PriceText>
          </AccordionDetails>
        </Accordion>
      </ThirdBox>
      <ThirdBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <HowText>Do I have to wait for someone to dispatch help?</HowText>
          </AccordionSummary>
          <AccordionDetails>
            <PriceText>
              No, with Truckifix, our application tracks down the nearest
              supplier to you and dispatches them through our application,
              prompting altering guidelines in emergency aides. Since we don't
              utilize a call community or manual dispatch process there is no
              cost wrangling with Specialist co-ops while you stand by abandoned
              out and about.
            </PriceText>
          </AccordionDetails>
        </Accordion>
      </ThirdBox>

      <ThirdBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <HowText>How much does the app cost?</HowText>
          </AccordionSummary>
          <AccordionDetails>
            <PriceText>
              The actual application is allowed to download. You essentially pay
              for emergency aides when you want it
            </PriceText>
          </AccordionDetails>
        </Accordion>
      </ThirdBox>

      <ThirdBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <HowText>How do I pay?</HowText>
          </AccordionSummary>
          <AccordionDetails>
            <PriceText>
              You can pay with a credit or check card directly through the
              application.
            </PriceText>
          </AccordionDetails>
        </Accordion>
      </ThirdBox>

      <ThirdBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <HowText>How do I reach someone at Truckifix?</HowText>
          </AccordionSummary>
          <AccordionDetails>
            <PriceText>
              You'll be capable call Truckifix straightforwardly, day in and day
              out through the application.
            </PriceText>
          </AccordionDetails>
        </Accordion>
      </ThirdBox>

      <ThirdBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <HowText>Who are the Service Providers?</HowText>
          </AccordionSummary>
          <AccordionDetails>
            <PriceText>
              Truckifix Specialist organizations include our organization of
              capable people prepared to assist you with getting back out and
              about. From shops and tow truck organizations to versatile
              mechanics and free thinkers, our suppliers are prepared to present
              to you the best emergency aides experience conceivable
            </PriceText>
          </AccordionDetails>
        </Accordion>
      </ThirdBox>

      <ThirdBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <HowText>Do you background check Service Providers?</HowText>
          </AccordionSummary>
          <AccordionDetails>
            <PriceText>
              All Roadsidex Specialist organizations are screened utilizing a
              seven-point up-and-comer check. We screen broadly, statewide, and
              even at the district level, to present to you the most confided in
              suppliers that anyone could hope to find. Our suppliers are
              guaranteed and prepared in wellbeing rehearses, so when you're out
              and about, they'll know how to help.
            </PriceText>
          </AccordionDetails>
        </Accordion>
      </ThirdBox>

      <ThirdBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <HowText>What if I don’t have a spare?</HowText>
          </AccordionSummary>
          <AccordionDetails>
            <PriceText>
              Truckifix is dealing with adding a help where you can arrange a
              tire right from our application, and even tire fix, however
              meanwhile, we suggest you request a tow truck administration.
            </PriceText>
          </AccordionDetails>
        </Accordion>
      </ThirdBox>

      <ThirdBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <HowText>What if my vehicle wheel isn’t accessible?</HowText>
          </AccordionSummary>
          <AccordionDetails>
            <PriceText>
              We suggest you request a tow truck on the off chance that your
              punctured tire isn't effectively open.
            </PriceText>
          </AccordionDetails>
        </Accordion>
      </ThirdBox>
    </MainBoxSec>
  );
};

export default FlatSecond;
