import React from "react";
import { styled, Box, Typography } from "@mui/material";

import InstagramIcon from "@mui/icons-material/Instagram";
import Feadback from "@mui/icons-material/Facebook";
import MassageIcon from "@mui/icons-material/LinkedIn";

import { Link } from "react-router-dom";

const MainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "50px",
  background: "rgb(41, 57, 141)",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-17px",
    display: "block",
    height: "100px",
    overflow: "hidden",
  },
}));

const NumberBox = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  marginLeft: "161px",
  fontWeight: "600",
  color: "white",
  marginTop: "17px",
  fontSize: "15px",
  [theme.breakpoints.down("lg")]: {
    padding: "10px",
    marginLeft: "100px",
    fontWeight: "600",
    fontSize: "15px",
    overflow: "hidden",
  },
}));

const IconBox = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  color: "white",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "205px",
    marginTop: "-48px",
    overflow: "hidden",
  },
}));

const Tiwiter = styled(Box)(({ theme }) => ({
  marginLeft: "25px",
  marginTop: "15px",
  overflow: "hidden",
}));

const FaceBook = styled(Box)(({ theme }) => ({
  marginLeft: "65px",
  marginTop: "-30px",
  overflow: "hidden",
}));

const Message = styled(Box)(({ theme }) => ({
  marginLeft: "110px",
  marginTop: "-30px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "100px",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  marginLeft: "44rem",
  marginTop: "14px",
  letterSpacing: "1px",
  fontSize: "15px",
  fontWeight: "500",
  color: "white",
  [theme.breakpoints.down("lg")]: {
    color: "white",
    marginLeft: "40px",
    marginTop: "10px",
  },
}));

const TextNewBox = styled(Box)(({ theme }) => ({
  color: "white",
  marginTop: "13px",
  marginLeft: "15px",
  overflow: "hidden",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "280px",
    marginTop: "-39px",
    overflow: "hidden",
  },
}));

const TextGetBox = styled(Typography)(({ theme }) => ({
  paddingLeft: "15px",
  color: "white",
  marginTop: "12px",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "240px",
    marginTop: "-24px",
    height: "40px",
  },
}));

const Navbar = () => {
  return (
    <>
      <MainBox>
        <a href="tel:+18772210727">
          <NumberBox>(877)221-0727</NumberBox>
        </a>

        <IconBox>
          <Tiwiter>
            <a
              href="https://www.instagram.com/truckifix/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon style={{ color: "white" }} />
            </a>
          </Tiwiter>

          <a
            href="https://www.facebook.com/profile.php?id=61565054793339"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaceBook>
              <Feadback style={{ color: "white" }} />
            </FaceBook>
          </a>

          <a
            href="https://www.linkedin.com/in/truckifix/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Message>
              <MassageIcon style={{ color: "white" }} />
            </Message>
          </a>
        </IconBox>
        <Text>
          <Link to="/become" style={{ color: "white", textDecoration: "none" }}>
            Become a Service Provider
          </Link>
        </Text>

        <TextGetBox> | </TextGetBox>
        <TextNewBox>
          <Link to="/login" style={{ color: "white", textDecoration: "none" }}>
            Login
          </Link>
        </TextNewBox>
      </MainBox>
    </>
  );
};

export default Navbar;
