import React from "react";

import House from "../Header/House";

import { Box, styled } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import AdbIcon from "@mui/icons-material/Adb";
import FlatSecond from "./FlatSecond";

const MainBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
  backgroundRepeat: "no-repeat, repeat",
  height: "600px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  color: "#fff",
  backgroundAttachment: "fixed",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-10px",
    display: "block",
    height: "830px",
    overflow: "hidden",
  },
}));

const TextBox = styled(Box)(({ theme }) => ({
  fontSize: "45px",
  wordWrap: "break-word",
  marginTop: "100px",
  marginLeft: "170px",
  letterSpacing: "2px",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
    wordWrap: "break-word",
    padding: "25px",
    marginTop: "10px",
    marginLeft: "40px",
    letterSpacing: "2px",
    fontWeight: "400",
  },
}));

const TextBoxGet = styled(Box)(({ theme }) => ({
  fontSize: "15px",
  marginTop: "10px",
  wordWrap: "break-word",
  marginLeft: "170px",
  letterSpacing: "2px",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    fontSize: "10px",
    marginTop: "-17px",
    wordWrap: "break-word",
    marginLeft: "20px",
    width: "90%",
    letterSpacing: "1px",
    fontWeight: "400",
    textAlign: "center",
  },
}));

const KartBox = styled(Box)(({ theme }) => ({
  width: "240px",
  height: "70px",
  background: "rgb(41, 57, 141)",
  marginLeft: "170px",
  marginTop: "15px",
  borderRadius: "8px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "75px",
  },
}));

const TypeTextBox = styled(Box)`
  margin-left: 17px;
  width: 100%;
  padding: 22px;
  font-size: 16px;
`;

const AndriodIcon = styled(Box)`
  margin-left: 170px;
  margin-top: -56px;
`;
const ImageNew = styled("img")(({ theme }) => ({
  width: "230px",
  height: "460px",
  marginRight: "300px",
  marginTop: "75px",
  [theme.breakpoints.down("lg")]: {
    width: "200px",
    height: "400px",
    marginLeft: "95px",
    marginTop: "20px",
  },
}));

const Mobile = () => {
  return (
    <>
      <House />

      <MainBox>
        <Box>
          <TextBox>Need to find a trusted mechanic? We can help.</TextBox>
          <TextBoxGet>
            At Truckifix, we vet and personal investigation all our specialist
            co-ops so you can take advantage of a confided in organization of
            mechanics. Essentially download our application and solicitation a
            versatile repairman to come to you with the bit of a button. Get a
            good deal on tows to shops, or save time while getting your oil
            changed at home with our helpful assistance.
          </TextBoxGet>

          <KartBox>
            <TypeTextBox>Download Now </TypeTextBox>
            <AndriodIcon>
              <AppleIcon style={{ fontSize: "40px" }} />
            </AndriodIcon>
          </KartBox>

          <KartBox>
            <TypeTextBox>Download Now </TypeTextBox>
            <AndriodIcon>
              <AdbIcon style={{ fontSize: "40px" }} />
            </AndriodIcon>
          </KartBox>
        </Box>
        <ImageNew src="Image/89354.png" alt="" />
      </MainBox>

      <FlatSecond />
    </>
  );
};

export default Mobile;
