import React from 'react'

import House from '../Header/House'

import { Box, styled } from '@mui/material'
import AppleIcon from '@mui/icons-material/Apple';
import AdbIcon from '@mui/icons-material/Adb';
import FlatSecond from './FlatSecond';



const MainBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '600px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]: {
        marginTop: '-10px',
        display: 'block',
        height: '840px',
        overflow: 'hidden',
    }
}))

const TextBox = styled(Box)(({ theme }) => ({
    fontSize: '45px',
    wordWrap: 'break-word',
    marginTop: '100px',
    marginLeft: '170px',
    letterSpacing: '2px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]: {
        fontSize: '20px',
        wordWrap: 'break-word',
        padding: '25px',
        marginTop: '10px',
        marginLeft: '30px',
        letterSpacing: '2px',
        fontWeight: '400',
    }
}))


const TextBoxGet = styled(Box)(({ theme }) => ({
    fontSize: '15px',
    marginTop: '10px',
    wordWrap: 'break-word',
    marginLeft: '170px',
    letterSpacing: '2px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]: {
        fontSize: '10px',
        marginTop: '-20px',
        wordWrap: 'break-word',
        marginLeft: '55px',
        width: '80%',
        letterSpacing: '1px',
        fontWeight: '400',
    }
}))






const ImageNew = styled('img')(({ theme }) => ({
    width: '230px',
    height: '460px',
    marginRight: '300px',
    marginTop: '75px',
    [theme.breakpoints.down('lg')]: {
        width: '200px',
        height: '400px',
        marginLeft: '95px',
        marginTop: '30px',
    }
}))


const KartBox = styled(Box)(({ theme }) => ({
    width: '240px',
    height: '70px',
    background: 'rgb(41, 57, 141)',
    marginLeft: '170px',
    marginTop: '15px',
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '75px',
    }

}))


const TypeTextBox = styled(Box)`
    margin-left: 17px;
    width: 100%;
    padding: 22px;
    font-size: 16px;
  `;

const AndriodIcon = styled(Box)`
    margin-left: 170px;
    margin-top: -56px;
`;





const TowTruck = () => {
    return (
        <>
            <House />

            <MainBox>
                <Box>
                    <TextBox>Need a tow truck? We can help.</TextBox>
                    <TextBoxGet>Stuck out and about? We can get you a tow truck quick with our on-request and computerized application. When you download the application, basically select the tow truck administration and our framework will consequently associate you with the specialist organization nearest to you. No more value wrangling and outsider organizing while you stand by.</TextBoxGet>

                    <KartBox>
                        <TypeTextBox>Download Now </TypeTextBox>
                        <AndriodIcon>
                            <AppleIcon style={{ fontSize: '40px' }} />
                        </AndriodIcon>
                    </KartBox>

                    <KartBox>
                        <TypeTextBox>Download Now </TypeTextBox>
                        <AndriodIcon>
                            <AdbIcon style={{ fontSize: '40px' }} />
                        </AndriodIcon>
                    </KartBox>

                </Box>
                <ImageNew src="Image/89354.png" alt="" />
            </MainBox>


            <FlatSecond />
        </>
    )
}

export default TowTruck
