import React from "react";
import House from "../Header/House";
import { Link } from "react-router-dom";
import { Box, styled } from "@mui/material";

const MainBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
  backgroundRepeat: "no-repeat, repeat",
  height: "600px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  color: "#fff",
  overflow: "hidden",
  backgroundAttachment: "fixed",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-10px",
    display: "block",
    height: "250px",
    overflow: "hidden",
  },
}));

const TextBox = styled(Box)(({ theme }) => ({
  fontSize: "54px",
  lineHeight: "1.2",
  marginLeft: "300px",
  marginTop: "180px",

  letterSpacing: "2px",
  fontWeight: "600",
  overflow: "hidden",
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
    wordWrap: "break-word",
    padding: "25px",
    textAlign: "center",
    marginTop: "10px",
    marginLeft: "10px",
    letterSpacing: "1px",
    fontWeight: "400",
    overflow: "hidden",
  },
}));

const TextBoxGet = styled(Box)(({ theme }) => ({
  width: "60%",
  fontSize: "15px",
  marginLeft: "310px",
  marginTop: "30px",
  textAlign: "center",
  letterSpacing: "1px",
  fontWeight: "600",
  overflow: "hidden",
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    fontSize: "10px",
    textAlign: "center",
    marginTop: "-5px",
    wordWrap: "break-word",
    marginLeft: "18px",
    letterSpacing: "0px",
    fontWeight: "400",
    overflow: "hidden",
  },
}));

const ButtomBox = styled(Box)(({ theme }) => ({
  marginTop: "30px",
  marginLeft: "610px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "55px",
    marginTop: "20px",
  },
}));

const ImageLogoBox = styled("img")({
  width: "100px",
  height: "50px",
});

const TableBox = styled(Box)(({ theme }) => ({
  display: "flex",
  marginLeft: "220px",
  [theme.breakpoints.down("lg")]: {
    display: "block",
    marginLeft: "27px",
  },
}));

const ImageNew = styled("img")(({ theme }) => ({
  width: "230px",
  height: "460px",
  marginLeft: "200px",
  marginTop: "50px",

  [theme.breakpoints.down("lg")]: {
    width: "150px",
    height: "300px",
    marginLeft: "90px",
    marginTop: "-10px",
    marginBottom: "30px",
  },
}));

const BecomeService = () => {
  return (
    <>
      <House />

      <MainBox>
        <Box>
          <TextBox>Turn into a Specialist co-op Today!</TextBox>
          <TextBoxGet>
            Truckifix is making it simpler than any time in recent memory to
            bring in cash via mechanizing and digitizing the emergency aides
            industry. You needn't bother with a tow truck to change a punctured
            tire or bring somebody gas. You pick the administrations you need to
            give, and you pick when you need to work.
          </TextBoxGet>

          <ButtomBox>
            <Link type="submit" to="#" className="btn btn-danger">
              BECOME A SERVICE PROVIDER
            </Link>
          </ButtomBox>
        </Box>
      </MainBox>

      <TableBox>
        <table>
          <tr>
            <th>OTHER APPLICATIONS</th>

            <th>
              <ImageLogoBox src="Image/RX1.png" alt="" />
              Truckifix
            </th>
          </tr>
          <tr>
            <td>Value Wrangling</td>
            <td>Serious Compensation Base</td>
          </tr>
          <tr>
            <td>Level Expenses</td>
            <td>Value Window (you pick last cost)</td>
          </tr>
          <tr>
            <td>Telephone/Manual Dispatch/Call Center</td>
            <td>
              Computerized Dispatch (framework dispatches - no dispatcher
              contact)
            </td>
          </tr>
          <tr>
            <td>Can't Track down Client Area</td>
            <td>GPS to Client</td>
          </tr>
          <tr>
            <td>Need to Give Out Cell Number</td>
            <td>In-Application Correspondence</td>
          </tr>
          <tr>
            <td>Regularly scheduled Pay</td>
            <td>Week by week Pay (programmed store)</td>
          </tr>
          <tr>
            <td>Not Appreciated</td>
            <td>Rewards Program</td>
          </tr>
        </table>
        <ImageNew src="Image/89354.png" alt="" />
      </TableBox>
    </>
  );
};

export default BecomeService;
