import './App.css';


import Navbar from './Header/Navbar';


import {BrowserRouter , Routes, Route} from 'react-router-dom';

import Login from './Components/Login'

import Forget from './Components/Forget';
import Becom from './InsidePage/Becom';
import Flat from './InsidePage/Flat'
import TowTruck from './InsidePage/TowTruck';
import DeadBattery from './InsidePage/DeadBattery';
import OutFull from './InsidePage/OutFull';
import Mobile from './InsidePage/Mobile';
import LockOut from './InsidePage/LockOut';
import TripIns from './InsidePage/TripIns';
import NonEmergancy from './InsidePage/NonEmergancy';
import Location from './InsidePage/Location';
import HomeTwo from './Components/Home/HomeTwo';
import Footer from './Header/Footer'
import About from './InsidePage/About';
import IndustryPage from './InsidePage/IndustryPage';
import ContactPage from './InsidePage/ContactPage';
import WhoTheyAre from './InsidePage/WhoTheyAre';
import BecomeService from './InsidePage/BecomeService';
import TermsAndConditions from './InsidePage/TermsAndConditions';
import Privecy from './InsidePage/Privecy';
import Bussiness from './InsidePage/Bussiness';
import Insurance from './InsidePage/Insurance';
import DocumentDetails from './InsidePage/DocumentDetails';




function App() {
  return (
  
     <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path='/' element={<HomeTwo/>} />
              <Route path="/login" element={<Login/>} />
              <Route path='/forget' element={<Forget/>}/>
              <Route path='/become' element={<Becom/>} />
              <Route path='/flat' element={<Flat/>} />
              <Route path='/towtruck' element={<TowTruck/>}/>
              <Route path='/deadbatter' element={<DeadBattery/>} />
              <Route path='/outfull' element={<OutFull/>} />
              <Route path='/mobile' element={<Mobile/>} />
              <Route path='/lock' element={<LockOut/>} />
              <Route path='/trip' element={<TripIns/>} />
              <Route path='/nonemer' element={<NonEmergancy/>} />
              <Route path='/location' element= {<Location/>} />
              <Route path='/about' element={<About/>} />
              <Route path='/industry' element={<IndustryPage/>} />
              <Route path='/contactpage' element={<ContactPage/>} />
              <Route path='/whothe' element={<WhoTheyAre/>} />
              <Route path='/becomeservice' element={<BecomeService/>} />
              <Route path='/treamcondtion' element={<TermsAndConditions/>} />
              <Route path='/privecy' element={<Privecy/>} />
              <Route path='/business' element={<Bussiness/>} />
              <Route path='/insurance' element={<Insurance/>} />
              <Route path='/document' element={<DocumentDetails/>} />
             </Routes>
           
           <Footer/>
       </BrowserRouter>
        
       
     
  
  );
}

export default App;
