import { Box, styled } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import House from '../Header/House';


const FromBox = styled(Box)(({ theme }) => ({
  padding: '60px',
  width: '50%',
  marginLeft: '400px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    marginLeft: '0px',
  }
}))


const Login = () => {
  return (
    <>
      <House />
      <FromBox>

        <div className="form-outline mb-4">
          <label className="form-label" for="form2Example1">Email address</label>
          <input type="email" id="form2Example1" className="form-control" />
        </div>


        <div className="form-outline mb-4">
          <label className="form-label" for="form2Example2">Password</label>
          <input type="password" id="form2Example2" className="form-control" />
        </div>


        <div className="row mb-4">
          <div className="col d-flex justify-content-center">

            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="form2Example31" checked />
              <label className="form-check-label" for="form2Example31"> Remember me </label>
            </div>
          </div>

          <div className="col">

            <Link to="/forget">Forgot password?</Link>
          </div>
        </div>


        <Link type="button" to='/' className="btn btn-primary btn-block mb-4">Sign in</Link>


        <div className="text-center">
          <p>Not a member? <Link to='/become'>Register</Link></p>
          <p>or sign up with:</p>
          <button type="button" className="btn btn-link btn-floating mx-1">
            <i className="fab fa-facebook-f"></i>
          </button>

          <button type="button" className="btn btn-link btn-floating mx-1">
            <i className="fab fa-google"></i>
          </button>

          <button type="button" className="btn btn-link btn-floating mx-1">
            <i className="fab fa-twitter"></i>
          </button>

          <button type="button" className="btn btn-link btn-floating mx-1">
            <i className="fab fa-github"></i>
          </button>
        </div>
      </FromBox>
    </>
  )
}

export default Login

