import React from "react";

import House from "../Header/House";

import { Box, styled, Typography } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import AdbIcon from "@mui/icons-material/Adb";
import FlatSecond from "./FlatSecond";

const MainBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
  backgroundRepeat: "no-repeat, repeat",
  height: "600px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  color: "#fff",
  backgroundAttachment: "fixed",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-10px",
    display: "block",
    height: "800px",
    overflow: "hidden",
  },
}));

const TextBox = styled(Box)(({ theme }) => ({
  fontSize: "45px",
  wordWrap: "break-word",
  marginTop: "100px",
  marginLeft: "170px",
  letterSpacing: "2px",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
    wordWrap: "break-word",
    padding: "25px",
    marginTop: "10px",
    marginLeft: "10px",
    letterSpacing: "2px",
    fontWeight: "400",
  },
}));

const TextBoxGet = styled(Box)(({ theme }) => ({
  fontSize: "15px",
  marginTop: "10px",
  wordWrap: "break-word",
  marginLeft: "170px",
  letterSpacing: "2px",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    fontSize: "10px",
    marginTop: "-20px",
    width: "320px",
    marginLeft: "35px",

    fontWeight: "400",
  },
}));

const KartBox = styled(Box)(({ theme }) => ({
  width: "240px",
  height: "70px",
  background: "rgb(41, 57, 141)",
  marginLeft: "170px",
  marginTop: "15px",
  borderRadius: "8px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "75px",
  },
}));

const TypeTextBox = styled(Box)`
  margin-left: 17px;
  width: 100%;
  padding: 22px;
  font-size: 16px;
`;

const AndriodIcon = styled(Box)`
  margin-left: 170px;
  margin-top: -56px;
`;

const ImageNew = styled("img")(({ theme }) => ({
  width: "230px",
  height: "460px",
  marginRight: "300px",
  marginTop: "75px",
  [theme.breakpoints.down("lg")]: {
    width: "200px",
    height: "400px",
    marginLeft: "95px",
    marginTop: "30px",
  },
}));

const TairMainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  marginLeft: "340px",
  marginTop: "100px",
  [theme.breakpoints.down("lg")]: {
    display: "block",
    marginLeft: "25px",
    marginTop: "30px",
  },
}));

const ImageFont = styled("img")(({ theme }) => ({
  width: "770px",
  height: "250px",
  [theme.breakpoints.down("lg")]: {
    width: "300px",
    height: "180px",
    marginLeft: "18px",
  },
}));

const TextFildLast = styled(Typography)(({ theme }) => ({
  marginTop: "10px",
  width: "50%",
  marginLeft: "30px",
  color: "grey",
  fontSize: "20px",
  lineHeight: "28px",
  fontFamily: "'Open Sans', sans-serif}",
  [theme.breakpoints.down("lg")]: {
    fontSize: "15px",
    lineHeight: "0px",
    marginLeft: "100px",
  },
}));

const JumbText = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  marginLeft: "30px",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    marginTop: "20px",
    fontSize: "18px",
    marginLeft: "46px",
  },
}));

const DeadBattery = () => {
  return (
    <>
      <House />

      <MainBox>
        <Box>
          <TextBox>Vehicle would begin? We can help.</TextBox>
          <TextBoxGet>
            Regardless of where you are, getting going again is simple with
            Truckifix. Our specialist co-ops find you quick with GPS route, and
            will be naturally dispatched when you demand administration.
          </TextBoxGet>

          <KartBox>
            <TypeTextBox>Download Now </TypeTextBox>
            <AndriodIcon>
              <AppleIcon style={{ fontSize: "40px" }} />
            </AndriodIcon>
          </KartBox>

          <KartBox>
            <TypeTextBox>Download Now </TypeTextBox>
            <AndriodIcon>
              <AdbIcon style={{ fontSize: "40px" }} />
            </AndriodIcon>
          </KartBox>
        </Box>
        <ImageNew src="Image/89354.png" alt="" />
      </MainBox>

      <Box>
        <TairMainBox>
          <ImageFont
            src="https://alphatowtruckservices.com/wp-content/uploads/2020/10/JO-How-to-safely-b25C879-808x450-1.jpg"
            alt=""
          />
          <Box>
            <JumbText>Jump Start Car or Truck</JumbText>
            <TextFildLast>
              If your car or truck won’t turn on or you run out of gas on the
              highway, a technician can provide immediate service. If your car
              or truck won’t turn on or you run out of gas on the highway, a
              technician can provide immediate service.
            </TextFildLast>
          </Box>
        </TairMainBox>
      </Box>

      <FlatSecond />
    </>
  );
};

export default DeadBattery;
