import React from "react";
import House from "../Header/House";

import { styled, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const MainBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
  backgroundRepeat: "no-repeat, repeat",
  height: "400px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  color: "#fff",
  backgroundAttachment: "fixed",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-100px",
    display: "block",
    height: "100px",
  },
}));

const TextMain = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: "54px",
  lineHeight: "1.2",
  fontWeight: "600",
  textAlign: "center",
  marginLeft: "480px",
  marginTop: "150px",
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
    marginLeft: "-15px",
    marginTop: "100px",
    fontSize: "30px",
    padding: "26px",
  },
}));

const FormTextFild = styled(Box)(({ theme }) => ({
  display: "flex",
  marginLeft: "130px",
  marginTop: "50px",
  height: "500px",
  marginBottom: "80px",
  [theme.breakpoints.down("lg")]: {
    display: "block",
    height: "850px",
    marginLeft: "10px",
  },
}));

const FromBox = styled(Box)(({ theme }) => ({
  width: "50%",
  padding: "30px",
  color: "black",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const AddressBox = styled(Box)(({ theme }) => ({
  marginTop: "30px",
  marginLeft: "100px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0px",
  },
}));

const PhoneTextFild = styled(Typography)(({ theme }) => ({
  marginLeft: "100px",

  fontSize: "18px",
  lineHeight: "28px",
  color: "black",
  textDecoration: "none",
  fontFamily: "'Open Sans', sans-serif",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "10",
    fontSize: "15px",
    lineHeight: "25px",
  },
}));

const ImageRDX = styled("img")(({ theme }) => ({
  width: "200px",
  height: "150px",
  marginLeft: "100px",
  marginTop: "20px",
  marginBottom: "20px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "70px",
  },
}));

const ContactPage = () => {
  return (
    <>
      <House />
      <MainBox>
        <Box>
          <TextMain>Contact Truckifix</TextMain>
        </Box>
      </MainBox>

      <Box>
        <FormTextFild>
          <FromBox>
            <form
              action="https://formsubmit.co/https://formsubmit.co/el/confirm/7360bd8747b61d92afc955079a7bf33d"
              method="POST"
            >
              <div className="form-row">
                <div className="col">
                  <label for="inputEmail4">First Name </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First name"
                    name="firstname"
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="col">
                  <label for="inputEmail4">Last Name </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last name"
                    name="lastname"
                    required
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="form-group mt-3">
                <label for="inputAddress">Your Email (required)</label>
                <input
                  type="email"
                  className="form-control"
                  id="inputAddress"
                  placeholder="@gmail.com"
                  name="email"
                  required
                  autoComplete="off"
                />
              </div>

              <div className="form-group mt-3">
                <label for="inputAddress">Your Phone Number</label>
                <input
                  type="number"
                  className="form-control"
                  id="inputAddress"
                  placeholder="enter your number"
                  name="number"
                  required
                  autoComplete="off"
                />
              </div>

              <div class="form-group mt-3">
                <label for="exampleFormControlTextarea1">Your Message</label>
                <textarea
                  class="form-control"
                  name="message"
                  required
                  autoComplete="off"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>

              <Link type="submit" className="btn btn-danger">
                I WANT MORE INFO!
              </Link>
            </form>
          </FromBox>

          <AddressBox>
            <ImageRDX src="Image/RX1.png" alt="" />

            <PhoneTextFild>Contact : (877)221-0727</PhoneTextFild>

            <PhoneTextFild>
              25350 Magic Mountain Pkwy <br></br> Valencia, Suite 300, Santa
              Clarita, CA 91355
            </PhoneTextFild>
            <PhoneTextFild>support@Truckifix.com</PhoneTextFild>
            <PhoneTextFild>https://www.Truckifix.com</PhoneTextFild>
          </AddressBox>
        </FormTextFild>
      </Box>
    </>
  );
};

export default ContactPage;
