import React from 'react'

import { Box, styled, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import House from '../Header/House';

const MainBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://www.shell.com.au/motorists/truck-atlas/_jcr_content/pagePromo/image.img.960.jpeg/1528879489475/truckatlas.jpeg")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '580px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    color: '#fff',
    [theme.breakpoints.down('lg')]: {

        marginTop: '-100px',
        display: 'block',
        height: '600px',
    }
}))

const SecoBox = styled(Box)(({ theme }) => ({
    background: 'white',
    width: '50%',
    height: '580px',
    marginLeft: '400px',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        width: '100%',
        marginLeft: '0',
        overflow: 'hidden',
        marginTop: '100px',
        height: '600px',

    }
}))


const Image = styled('img')(({ theme }) => ({
    width: '72px',
    height: '72px',
    marginLeft: '345px',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '150px',

    }
}))


const BusseText = styled(Typography)`
    color: black;
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.1;
    
`;

const ThirdBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '95%',
    height: '35px',
    background: '#f5f5f5',
    borderRadius: '8px',
    marginLeft: '18px',

    [theme.breakpoints.down('lg')]: {
        width: '95%',
        height: '60px',
        marginLeft: '9px',
    }
}))


const PrimeText = styled(Typography)`
    color: black;
    text-align: center;
    padding: 6px;
    margin-left: 13px;
    font-size: 15px;
 `


const DetailText = styled(Typography)(({ theme }) => ({
    width: '20%',
    height: '35px',
    color: '#fff',
    backgroundColor: '#337ab7',
    borderColor: '#337ab7',
    textAlign: 'center',
    padding: '6px',
    fontWeight: '500',
    [theme.breakpoints.down('lg')]: {
        width: '30%',
        height: '60px',
    }

}))

const PrimeTextInsurance = styled(Typography)`
    color: black;
    text-align: center;
    padding: 6px;
    margin-left: 13px;
    font-size: 15px;
 `



const FromBox = styled(Box)`
    
    padding: 30px;
    color: black;
    font-weight: 600;
`

const Bussiness = () => {
    return (
        <>
        <House/>
        <MainBox>
            <SecoBox>
                <Image src="Image/RX1.png" alt="" />
                <BusseText>Business Provider Signup</BusseText>
                <ThirdBox>
                    <PrimeText>Primary Contact</PrimeText>
                    <DetailText>Business Details</DetailText>
                    <PrimeTextInsurance>Insurance Details</PrimeTextInsurance>
                    <PrimeText>Document Details</PrimeText>
                </ThirdBox>
                <FromBox>

                    <div className="form-group mt-2">
                        <label for="inputAddress">Company Name</label>
                        <input type="text" className="form-control" id="inputAddress" placeholder="Enter Your Company Name" />
                    </div>
                    <div className="form-group mt-2">
                        <label for="inputAddress">Company Address</label>
                        <input type="text" className="form-control" id="inputAddress" placeholder="Enter Your Company Address" />
                    </div>


                    <label for="inputAddress">Bank Details</label>
                    <div className="form-row">



                        <div className="col">
                            <label for="inputEmail4">Account Number</label>
                            <input type="number" className="form-control" placeholder="Enter Your Account Number" />
                        </div>
                        <div className="col">
                            <label for="inputEmail4">Routing Number</label>
                            <input type="number" className="form-control" placeholder="Enter Your Routing Number" />
                        </div>
                    </div>
                    <div className="form-row">
                        <Link type="submit" to='/become' className="btn btn-primary m-3">Back</Link>
                        <Link type="submit" to='/insurance' className="btn btn-primary m-3">Next</Link>
                    </div>
                </FromBox>
            </SecoBox>
        </MainBox>
        </>
    )
}

export default Bussiness
