import React from "react";

import House from "../Header/House";

import { Box, styled, Typography } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import AdbIcon from "@mui/icons-material/Adb";
import FlatSecond from "./FlatSecond";

const MainBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
  backgroundRepeat: "no-repeat, repeat",
  height: "600px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  color: "#fff",
  overflow: "hidden",
  backgroundAttachment: "fixed",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-10px",
    display: "block",
    height: "760px",
    overflow: "hidden",
  },
}));

const TextBox = styled(Box)(({ theme }) => ({
  fontSize: "45px",
  wordWrap: "break-word",
  marginTop: "100px",
  marginLeft: "170px",
  letterSpacing: "2px",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
    wordWrap: "break-word",
    padding: "25px",
    marginTop: "10px",
    marginLeft: "27px",
    letterSpacing: "2px",
    fontWeight: "400",
  },
}));

const TextBoxGet = styled(Box)(({ theme }) => ({
  fontSize: "15px",
  marginTop: "10px",
  wordWrap: "break-word",
  marginLeft: "170px",
  letterSpacing: "2px",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    fontSize: "10px",
    marginTop: "-20px",
    wordWrap: "break-word",
    marginLeft: "16px",
    width: "90%",
    textAlign: "center",
    letterSpacing: "1px",
    fontWeight: "400",
  },
}));

const KartBox = styled(Box)(({ theme }) => ({
  width: "240px",
  height: "70px",
  background: "rgb(41, 57, 141)",
  marginLeft: "170px",
  marginTop: "15px",
  borderRadius: "8px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "75px",
  },
}));

const TypeTextBox = styled(Box)`
  margin-left: 17px;
  width: 100%;
  padding: 22px;
  font-size: 16px;
`;

const AndriodIcon = styled(Box)`
  margin-left: 170px;
  margin-top: -56px;
`;

const ImageNew = styled("img")(({ theme }) => ({
  width: "230px",
  height: "460px",
  marginRight: "300px",
  marginTop: "75px",
  [theme.breakpoints.down("lg")]: {
    width: "200px",
    height: "400px",
    marginLeft: "95px",
    marginTop: "30px",
  },
}));

const TairMainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  marginLeft: "340px",
  marginTop: "100px",
  [theme.breakpoints.down("lg")]: {
    display: "block",
    marginLeft: "22px",
    marginTop: "30px",
  },
}));

const ImageFont = styled("img")(({ theme }) => ({
  width: "1070px",
  height: "250px",
  [theme.breakpoints.down("lg")]: {
    width: "300px",
    height: "180px",
    marginLeft: "23px",
  },
}));

const TexFild = styled(Typography)(({ theme }) => ({
  marginTop: "10px",
  width: "50%",
  marginLeft: "30px",
  color: "grey",
  fontSize: "20px",
  lineHeight: "28px",
  fontFamily: "'Open Sans', sans-serif",
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    fontSize: "15px",
    marginLeft: "4px",
    textAlign: "center",
    lineHeight: "20px",
  },
}));

const JumbText = styled(Typography)(({ theme }) => ({
  marginTop: "20px",
  fontSize: "22px",
  marginLeft: "30px",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "15px",
    fontSize: "18px",
  },
}));

const TairMainBoxSec = styled(Box)(({ theme }) => ({
  display: "flex",
  marginLeft: "340px",
  marginTop: "30px",
  [theme.breakpoints.down("lg")]: {
    display: "block",
    marginLeft: "30px",
    marginTop: "-20px",
  },
}));

const ImageFontSec = styled("img")(({ theme }) => ({
  width: "480px",
  height: "380px",
  marginRight: "350px",
  marginTop: "40px",
  [theme.breakpoints.down("lg")]: {
    width: "300px",
    height: "180px",
    marginLeft: "23px",
    marginTop: "5px",
  },
}));

const TexFildSec = styled(Typography)(({ theme }) => ({
  marginTop: "10px",
  width: "90%",
  marginLeft: "10px",
  color: "grey",
  fontSize: "18px",
  lineHeight: "28px",
  fontFamily: "'Open Sans', sans-serif",
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    marginLeft: "0",
    fontSize: "15px",
    lineHeight: "20px",
    textAlign: "center",
  },
}));

const JumbTextSec = styled(Typography)(({ theme }) => ({
  marginTop: "50px",
  fontSize: "22px",
  marginLeft: "10px",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
    marginLeft: "100px",
  },
}));

const LockOut = () => {
  return (
    <>
      <House />

      <MainBox>
        <Box>
          <TextBox>Locked out? We can help.</TextBox>
          <TextBoxGet>
            We comprehend the need to get going that accompanies being locked
            out and at Mach1 you can arrange a locksmith with the dash of a
            button. A specialist co-op will be naturally dispatched to help.
          </TextBoxGet>

          <KartBox>
            <TypeTextBox>Download Now </TypeTextBox>
            <AndriodIcon>
              <AppleIcon style={{ fontSize: "40px" }} />
            </AndriodIcon>
          </KartBox>

          <KartBox>
            <TypeTextBox>Download Now </TypeTextBox>
            <AndriodIcon>
              <AdbIcon style={{ fontSize: "40px" }} />
            </AndriodIcon>
          </KartBox>
        </Box>
        <ImageNew src="Image/89354.png" alt="" />
      </MainBox>

      <Box>
        <TairMainBox>
          <ImageFont src="Image/2.jpg" alt="" />
          <Box>
            <JumbText>Emergency Vehicle Lockout Service</JumbText>
            <TexFild>
              Locked out of your vehicle? We will send a licensed locksmith or
              roadside technician to unlock your car, truck or semi-truck.
              Locked out of your vehicle? We will send a licensed locksmith or
              roadside technician to unlock your car, truck or semi-truck.{" "}
            </TexFild>
          </Box>
        </TairMainBox>
      </Box>

      <Box>
        <TairMainBoxSec>
          <Box>
            <JumbTextSec>Home Lockouts</JumbTextSec>
            <TexFildSec>
              Getting locked out of your own house is frustrating and it can be
              downright scary. Because you’re anxious to get into your home
              quickly, you might take drastic measures and try to break into
              your own home by breaking a window or a lock. This is the last
              thing you should do because you’ll cause unnecessary damage to
              your home that is expensive to repair. Instead, call the
              professionals at Truckifix. We will come to you and can use our
              special tools to unlock doors and get you safely back into your
              home.{" "}
            </TexFildSec>
          </Box>
          <ImageFontSec src="Image/12.jpeg" alt="" />
        </TairMainBoxSec>
      </Box>

      <FlatSecond />
    </>
  );
};

export default LockOut;
