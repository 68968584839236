import React from "react";
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import FacebookIcon from "@mui/icons-material/Facebook";
import MassageIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <MDBFooter className="text-center" color="white" bgColor="dark">
      <MDBContainer className="p-4">
        <section className="mb-4">
          <a
            outline
            color="light"
            floating
            className="m-3"
            href="https://www.instagram.com/truckifix/"
            target="_blank"
            rel="noopener noreferrer"
            role="button"
          >
            <InstagramIcon style={{ fontSize: "30px", color: "white" }} />
          </a>
          <a
            outline
            floating
            className="m-3"
            href="https://www.facebook.com/profile.php?id=61565054793339"
            target="_blank"
            rel="noopener noreferrer"
            role="button"
          >
            <FacebookIcon style={{ fontSize: "30px", color: "white" }} />
          </a>

          <a
            outline
            floating
            className="m-3"
            href="https://www.linkedin.com/in/truckifix/"
            target="_blank"
            rel="noopener noreferrer"
            role="button"
          >
            <MassageIcon style={{ fontSize: "30px", color: "white" }} />
          </a>
        </section>

        <section className="">
          <MDBRow>
            <MDBCol>
              <h5 className="text-uppercase">ABOUT US</h5>

              <ul className="list-unstyled ">
                <li style={{ margin: "5px" }}>
                  <Link
                    to="/treamcondtion"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Terms and Conditions
                  </Link>
                </li>
                <li style={{ margin: "5px" }}>
                  <Link
                    to="/privecy"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li style={{ margin: "5px" }}>
                  <a
                    href="https://truckifix.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Testimonials
                  </a>
                </li>
              </ul>
            </MDBCol>

            <MDBCol>
              <h5 className="text-uppercase">Contact Us</h5>

              <ul className="list-unstyled ">
                <li style={{ margin: "5px" }}>
                  <a
                    href="#!"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    United States
                  </a>
                </li>
                <li style={{ margin: "5px" }}>
                  <a
                    href="info@Truckifix.com"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Support
                  </a>
                </li>
              </ul>
            </MDBCol>

            <MDBCol>
              <h5 className="text-uppercase">HELP</h5>

              <ul className="list-unstyled ">
                <li style={{ margin: "5px" }}>
                  <a
                    href="#!"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Payments
                  </a>
                </li>
              </ul>
            </MDBCol>
          </MDBRow>
        </section>
      </MDBContainer>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2022 Copyright by:
        <a
          className="text-white"
          href="https://appoks.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          Truckifix
        </a>
      </div>
    </MDBFooter>
  );
};

export default Footer;
