import React from "react";
import House from "../Header/House";

import { Box, styled, Typography } from "@mui/material";

const MainBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
  backgroundRepeat: "no-repeat, repeat",
  height: "400px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  color: "#fff",
  backgroundAttachment: "fixed",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-100px",
    display: "block",
    height: "100px",
  },
}));

const TextMain = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: "54px",
  lineHeight: "1.2",
  fontWeight: "600",
  textAlign: "center",
  marginLeft: "430px",
  marginTop: "150px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "-15px",
    marginTop: "100px",
    textAlign: "center",
    fontSize: "30px",
    padding: "26px",
  },
}));

const AtTextFild = styled(Typography)(({ theme }) => ({
  color: "#29398d",
  fontSize: "32px",
  textAlign: "center",
  fontWeight: "600",
  textTransform: "none",
  marginTop: "30px",

  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
    marginTop: "10px",
  },
}));

const TheTextSec = styled(Typography)(({ theme }) => ({
  fontSize: "15px",
  width: "70%",
  marginLeft: "220px",
  marginBottom: "5px",
  marginTop: "10px",
  fontWeight: "500",
  textTransform: "none",

  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginLeft: "0",
    fontSize: "10px",
    padding: "5px",
  },
}));

const TermsAndConditions = () => {
  return (
    <>
      <House />
      <MainBox>
        <Box>
          <TextMain>“Terms And Condition”</TextMain>
        </Box>
      </MainBox>

      <Box>
        <AtTextFild>
          Truckifix Incorporated Dba: Truckifix, Terms of Service Agreement
        </AtTextFild>
        <TheTextSec>
          Truckifix, LLC (“Truckifix”) operates each Truckifix Platform, that
          links to these Terms of Service to provide online access to
          information about Truckifix and the products, services, and
          opportunities we provide. By accessing and using the Truckifix
          Platform, you agree to these Terms of Service.
        </TheTextSec>
        <TheTextSec>
          NOTICE: THIS AGREEMENT INCLUDES AN AGREEMENT THAT ALL CLAIMS OF DAMAGE
          THAT YOU OR Truckifix HAVE AGAINST EACH OTHER WILL BE BROUGHT THROUGH
          BINDING ARBITRATION NOT THOUGH A LAWSUIT IN A COURT ON AN INDIVIDUAL
          BASIS OR IN A CLASS ACTION, GROUP OR REPRESENTATIVE ACTION OR
          PROCEEDING. SERVICE PROVIDERS MAY OPT OUT OF SOME PROVISIONS OF THE
          ARBITRATION AGREEMENT. PLEASE REVIEW THE CLAUSE IN SECTION 15(j) OF
          THE ARBITRATION AGREEMENT PRIOR TO USING THE Truckifix PLATFORM.
        </TheTextSec>
        <TheTextSec>
          Truckifix reserves the right to modify these Terms of Service at any
          time without giving you prior notice. Your use of the Truckifix
          Platform following any such modification constitutes your agreement to
          follow and be bound by these Terms of Service as modified. The last
          date these Terms of Service were revised is set forth below.
        </TheTextSec>
        <AtTextFild>
          DEFINITIONS as used in this Terms of Service Agreement:
        </AtTextFild>
        <TheTextSec>
          Truckifix Platform includes mobile phone app and technology platform
          including website and any databases and systems that Truckifix uses to
          deliver the services.
        </TheTextSec>
        <TheTextSec>
          Service Providers are independent contractors that have a service
          account through Truckifix and who may be available to provide
          services. Clients are individuals 16 years of age or older with a
          Truckifix account who can request Services of Service Providers
          through the Truckifix platform.
        </TheTextSec>
        <TheTextSec>
          Services that are offered through the Truckifix Platform may include
          tow truck, tire change, lockout, dead battery, out of fuel, mobile
          mechanic, and trip inspection or other services listed on the
          Truckifix Platform.
        </TheTextSec>
        <TheTextSec>
          Content is the information, writings, images and/or other works that
          you see, hear or otherwise experience on the Truckifix Platform.
        </TheTextSec>
        <AtTextFild>1. Permitted Use of The Truckifix Platform</AtTextFild>
        <TheTextSec>
          Service Providers and Clients are collectively referred to as “Users”
          or “You”. You may use the Truckifix Platform, and the Content for
          services provided through the Truckifix Platform, or to learn about
          Truckifix products and services, and solely in compliance with these
          Terms of Service. The Truckifix Platform may only be used by Service
          Providers and Clients who are 16 years of age or older and have the
          ability to legally enter into this Agreement. By using the Truckifix
          Platform you represent that you are 18 years of age or older and that
          you agree to the terms of this Terms of Service Agreement, or that you
          are 16 years of age or older and have approval from a parent or
          guardian to enter into this Agreement, and that your parent or
          guardian agrees to be bound by this Agreement based on your actions.
        </TheTextSec>
        <AtTextFild>2. Prohibited Use of The Truckifix Platform</AtTextFild>
        <TheTextSec>
          By accessing the Truckifix Platform, you agree to the following.
        </TheTextSec>
        <TheTextSec>
          1. You will not use the Truckifix Platform in violation of these Terms
          of Service.
        </TheTextSec>
        <TheTextSec>
          2. You will not copy, modify, create a derivative work from, reverse
          engineer or reverse assemble the Truckifix Platform, or otherwise
          attempt to discover any source code, or allow any third party to do
          so.
        </TheTextSec>
        <TheTextSec>
          3. You will not sell, assign, sublicense, distribute, commercially
          exploit, grant a security interest in or otherwise transfer any right
          in, or make available to a third party, the Content or Service in any
          way.
        </TheTextSec>
        <TheTextSec>
          4. You will not use or launch any automated system, including without
          limitation, “robots,” “spiders,” or “offline readers,” that accesses
          the Truckifix Platform in a manner that sends more request messages to
          the Truckifix servers in a given period of time than a human can
          reasonably produce in the same period by using a conventional on-line
          web browser.
        </TheTextSec>
        <TheTextSec>
          5. You will not use the Truckifix Platform in any manner that damages,
          disables, overburdens, or impairs any Truckifix Platform or interferes
          with any other party’s use and enjoyment of the Truckifix Platform.
        </TheTextSec>
        <TheTextSec>
          6. You will not mirror or frame the Truckifix Platform or any part of
          it on any other Platform or web page.
        </TheTextSec>
        <TheTextSec>
          7. You will not attempt to gain unauthorized access to the Truckifix
          Platform.
        </TheTextSec>
        <TheTextSec>
          8. You will not access the Truckifix Platform by any means other than
          through the interface that is provided by Truckifix for use in
          accessing the Truckifix Platform.
        </TheTextSec>
        <TheTextSec>
          9. You will not impersonate another individual or company using the
          Truckifix Platform.
        </TheTextSec>
        <TheTextSec>
          10. You will not share your username and password with others or
          otherwise share your account with another individual, with the
          exception of family plans, where immediate family share a login.
        </TheTextSec>
        <TheTextSec>
          11. You will not discriminate against or harass any person based on
          their race, national origin, religion, gender, gender identity,
          physical or mental disability, medical condition, marital status, age
          or sexual orientation or any other characteristic protected under
          applicable federal or state law. Such discrimination includes but is
          not limited to, refusing to provide services based on any of these
          characteristics. Anyone found to have violated this prohibition will
          lose access to the Truckifix app and Service calls at Truckifix, LLC’s
          discretion.
        </TheTextSec>
        <TheTextSec>
          12. You will not use the Truckifix Platform for any purpose or in any
          manner that is unlawful.
        </TheTextSec>
        <TheTextSec>
          Any unauthorized use of any Content or the Truckifix Platform may
          violate patent, copyright, trademark, and other laws.
        </TheTextSec>
        <AtTextFild>3. Charges</AtTextFild>
        <TheTextSec>
          There is no charge to Clients for downloading the App or using the
          Truckifix Platform to learn about Services. As a Client, however, you
          understand that when you request Services through the Truckifix
          Platform from a Service Provider, that you may be charged for Services
          that you receive.
        </TheTextSec>
        <TheTextSec>
          Service Fee. You may be charged a service fee for a Service Provider
          to come to your location when requested, regardless of whether
          services are actually provided.
        </TheTextSec>
        <TheTextSec>
          Cancellation Fee. If you request services through the Truckifix
          Platform and agree to use a Service Provider you may be charged a
          cancellation fee if you cancel the services before the Service
          Provider renders service to your vehicle.
        </TheTextSec>
        <TheTextSec>
          Tips. After you receive Services from a Service Provider you may
          choose to tip your Service Provider in cash. Payments for Services
          will be processed through the Truckifix Platform separately from tips.
        </TheTextSec>
        <TheTextSec>
          Payment Processing. Payments will be processed through a payment
          processing service (e.g. Stripe, Inc., Paypal, or other services)
          Truckifix may change the company that provides payment processing
          without notice to the Client or the Service Provider.
        </TheTextSec>
        <TheTextSec>
          No Refunds. All charges are non-refundable. This refund policy will
          apply at all times regardless of the availability of the Truckifix
          Platform for your use or your cancellation of your Truckifix account.
        </TheTextSec>
        <TheTextSec>
          Credit Card and Bank Charges. When services are authorized by the
          Client, Truckifix may initiate authorization of a Client’s credit or
          debit card for an authorization amount. Although the authorization is
          not a charge, the authorization amount may reduce the available
          balance from a bank or credit card company even before the charges are
          processed. If your available funds in your account or available credit
          is insufficient to cover charges you may be charged fees by your
          credit card company or bank. Truckifix is not responsible for the bank
          or credit card charges, and we do not assist you in recovering these
          fees.
        </TheTextSec>
        <AtTextFild>4. Complaints</AtTextFild>
        <TheTextSec>
          Truckifix does not tolerate the use of alcohol or drugs by Service
          Providers using the Truckifix app while on Service calls. If you
          believe your Service Provider may be under the influence of drugs or
          alcohol, please have the Service Provider discontinue service
          immediately. Then after the Service Provider has ended the service,
          please report any feedback to Truckifix at support@Truckifix.com
        </TheTextSec>
        <AtTextFild>5. Service Provider Compensation</AtTextFild>
        <TheTextSec>
          In consideration for Service Provider’s performance of the Services,
          Truckifix shall pay Service Provider every two days through the
          payment processing platform, minus 15% (fifteen percent) fee of total
          Services provided and minus insurance if Service Provider has opted to
          use Truckifix insurance. Other fees that may be deducted from a
          Service Provider’s payment may include background check fees, or
          equipment costs, or starter kits. The use of Truckifix insurance as a
          Service Provider does not constitute an employer/employee relationship
          and Service Provider is still considered an independent contractor.
          Payment to Service Providers may be delayed until a future week based
          on processing times for payments and documentation.{" "}
        </TheTextSec>
        <AtTextFild>
          6. Service Provider Representations, Warranties and Agreements
        </AtTextFild>
        <TheTextSec>
          As a Service Provider through the Truckifix Platform, you represent,
          warrant and agree that:
        </TheTextSec>
        <TheTextSec>1. You are at least 21 years old;</TheTextSec>
        <TheTextSec>2. You have a valid driver’s license;</TheTextSec>
        <TheTextSec>
          3. Truckifix may obtain information about you including a background
          check which includes criminal and driving records, and you agree to
          provide any necessary authorization to obtain information for the
          purpose of a background check;
        </TheTextSec>
        <TheTextSec>
          4. Your vehicle that you use for providing Services through the
          Truckifix Platform is properly registered and insured;
        </TheTextSec>
        <TheTextSec>
          5. You agree to use insurance provided through Truckifix unless you
          have provided proof of insurance and received a waiver of insurance
          through Truckifix, and if you dismiss the insurance provided by
          Truckifix you warrant that you carry and maintain equivalent
          insurance;
        </TheTextSec>
        <TheTextSec>
          6. You will provide your own equipment to complete the Services that
          you have agreed to provide, as an independent contractor;
        </TheTextSec>
        <TheTextSec>
          7. Truckifix is not responsible for any injury, bodily or otherwise,
          incurred while providing Services;
        </TheTextSec>
        .
        <TheTextSec>
          8. All costs and expenses incurred by Service Provider in connection
          with the performance of the Services shall be the sole responsibility
          of and paid by Service Provider including any applicable local, state,
          and federal taxes based on the services you provide;
        </TheTextSec>
        <TheTextSec>
          9. You understand that Truckifix reserves the right to allow
          participation in the Truckifix Platform or to exclude a Service
          Provider from the Truckifix Platform at any time and without a reason;
        </TheTextSec>
        <TheTextSec>
          10. You will not use alcohol or drugs, or be under the influence of
          alcohol or drugs while providing Services;
        </TheTextSec>
        <TheTextSec>
          11. If you posses a firearm while on a service call, you warrant that
          the firearm is owned and possessed legally, and that you have received
          firearm safety training and have the experience and ability to posses
          and use a firearm safely, and will only use a firearm while providing
          Services when necessary for self-defense; and
        </TheTextSec>
        <TheTextSec>
          12. You understand that Truckifix is not to be held responsible for
          any actions associated with the use of a firearm, and you indemnify
          Truckifix against all damages and costs Truckifix incurs related to
          your possession and use of a firearm in any way associated with
          Truckifix or the Truckifix Platform.
        </TheTextSec>
        <AtTextFild>Independent Contractor Agreement:</AtTextFild>
        <TheTextSec>
          As a Service Provider, I understand that I am responsible for filing
          my own taxes with the Internal Revenue Service and Resident State
          Revenue Department. Truckifix or a partner of Truckifix such as the
          payment processing company, will provide me with a 1099 form.
        </TheTextSec>
        <TheTextSec>
          I understand it is not the responsibility of Truckifix to reimburse me
          for personal expenses. I will not be compensated for personal expenses
          incurred during service calls such as and not limited to: meals,
          cellular phone usage, motor vehicle violation tickets, tolls, as well
          as other expenses.
        </TheTextSec>
        <TheTextSec>
          I understand that Truckifix, LLC does not guarantee, nor am I
          obligated to Service a specific number of customers, or earn a
          specific amount of revenue, or work a specific amount of hours, or
          work specific days of the week. Any revenue I generate or pay I
          receive from Truckifix will be solely for individual jobs I complete.
        </TheTextSec>
        <TheTextSec>
          I understand that Truckifix does not guarantee a specific or average
          amount of revenue/pay. I retain full control and discretion on any
          Service jobs I choose to accept, I retain full control and discretion
          on the amount of hours I choose to work, I retain full control and
          discretion on the hours and days I choose to work; thus I retain full
          discretion on the potential revenue I may or may not generate based on
          the Service jobs I accept or do not accept to complete for Truckifix.
        </TheTextSec>
        <TheTextSec>
          I understand that Truckifix has a zero tolerance policy for violation
          of this Agreement and does not permit anything that is deemed
          contraband or illegal by the laws of the United States of America and
          accept full responsibility and penalties due to my own actions
          regarding the zero tolerance policy and violations of this Terms of
          Service Agreement.
        </TheTextSec>
        <TheTextSec>
          I understand that I am responsible for the service on my own vehicle
          used to provide Services to Truckifix customers.
        </TheTextSec>
        <TheTextSec>
          If required for Services I agree to provide, I will keep all DOT
          requirements up-to-date and certified. As well as any tow truck
          operator certifications required for my city and state of operation.
        </TheTextSec>
        <TheTextSec>
          I understand that any training or procedural instructions I receive
          from Truckifix may be limited in scope and Truckifix is not liable for
          any incident that may occur from Services delivered via the Truckifix
          application.
        </TheTextSec>
        <AtTextFild>7. Service Provider Equipment</AtTextFild>
        <TheTextSec>
          As an independent contractor, each Service Provider will provide and
          maintain in good working order equipment to complete the Services that
          they agree to provide.{" "}
        </TheTextSec>
        <TheTextSec>
          This may include a vehicle or tow truck used to render Services, or
          any tools or technology used to render services including a mobile
          phone. Truckifix is not responsible for any damage caused to equipment
          while performing Services.{" "}
        </TheTextSec>
        <TheTextSec>
          Truckifix may provide safety equipment (such as safety vests,
          flashers, and ID badges) or other equipment for purchase to Service
          Providers through the Truckifix Platform or through other means.
          Truckifix does not, however, warrant that the equipment is adequate to
          provide safety, as it is the Service Provider’s responsibility to
          determine whether all purchased equipment meets regulatory and
          personal safety standards.{" "}
        </TheTextSec>
        <TheTextSec>
          {" "}
          Truckifix AND ITS SERVICE PROVIDERS, LICENSORS AND SUPPLIERS MAKE NO
          REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
          TIMELINESS, SECURITY OR ACCURACY OF THE EQUIPMENT OFFERED THROUGH THE
          Truckifix PLATFORM OR OTHER MEANS FOR ANY PURPOSE.{" "}
        </TheTextSec>
        <TheTextSec>
          Service Providers are not required to purchase any equipment through
          Truckifix and may provide their own equipment including safety
          equipment from any source.{" "}
        </TheTextSec>
        <TheTextSec>
          A Service Provider, however, will need minimum safety equipment to
          qualify as a service provider, and will be sent and charged for safety
          equipment when the Service Provider signs up for an account unless
          they are granted an exemption by showing that they already possess the
          safety equipment.
        </TheTextSec>
        <AtTextFild>8. Copyrights and Trademarks</AtTextFild>
        <TheTextSec>
          The Truckifix Platform is based upon proprietary Truckifix technology
          and includes the Content. The Truckifix Platform is protected by
          applicable intellectual property and other laws, including trademark
          and copyright laws. The Truckifix Platform, including all intellectual
          property rights in the Truckifix Platform, belongs to and is the
          property of Truckifix or its licensors (if any). Truckifix owns and
          retains all copyrights in the Content. Except as specifically
          permitted on the Truckifix Platform as to certain Content, the Content
          may not be copied, reproduced, modified, published, uploaded, posted,
          transmitted, performed, or distributed in any way, and you agree not
          to modify, rent, lease, loan, sell, distribute, transmit, broadcast,
          or create derivative works based on the Content or the Truckifix
          Platform, in whole or in part, by any means. Truckifix, Design, logos,
          and other marks used by Truckifix from time to time are trademarks and
          the property of Truckifix. The appearance, layout, color scheme, and
          design of the Truckifix Platform are protected trade dress. User does
          not receive any right or license to use the foregoing. Truckifix may
          use and incorporate into the Truckifix Platform any suggestions or
          other feedback you provide, without payment or condition.
        </TheTextSec>
        <TheTextSec>
          Pursuant to Title 17, United States Code, Section 512(c)(2),
          notifications of claimed copyright infringement on the Truckifix
          Platform or the Service should be sent to Truckifix’s designated
          Copyright Agent.
        </TheTextSec>
        <TheTextSec>
          1. a. Copyright. You warrant that any content that you enter, or
          upload does not violate the copyright rights of others. If you believe
          that someone has violated your copyright rights, you may send us a
          notice according to the Digital Millennium Copyright Act (DMCA) as
          outlined below.
        </TheTextSec>
        <TheTextSec>
          IF YOU USE THE DMCA COPYRIGHT INFRINGEMENT NOTICE TO KNOWINGLY MAKE A
          FALSE CLAIM OF COPYRIGHT INFRINGEMENT YOU MAY BE LIABLE FOR LARGE
          CIVIL PENALTIES WHICH MAY INCLUDE MONETARY DAMAGES, COURT COSTS, AND
          ATTORNEYS’ FEES INCURRED BY US, BY ANY COPYRIGHT OWNER, OR BY ANY
          COPYRIGHT OWNER’S LICENSEE THAT SUFFERS ANY LOSS AS A RESULT OF
          RELYING UPON YOUR FALSE COPYRIGHT CLAIM. YOU MAY ALSO BE SUBJECT TO
          CRIMINAL PROSECUTION FOR PERJURY.
        </TheTextSec>
        <TheTextSec>
          Notice of Infringement under the DMCA must be sent by email or mail
          to:
        </TheTextSec>
        <TheTextSec>
          Under the DMCA Title 17, United States Code, Section 512(c)(3)(A),
          requires that the
        </TheTextSec>
        <AtTextFild>
          Notification of Claimed Infringement must include the following:
        </AtTextFild>
        <TheTextSec>
          1. A physical or electronic signature of a person authorized to act on
          behalf of the owner of the copyrighted work.
        </TheTextSec>
        <TheTextSec>
          2. Identification of the copyrighted work (or works) that you claim
          are infringed.
        </TheTextSec>
        <TheTextSec>
          {" "}
          iii. Identification of the specific material that is claimed to be
          infringed from the copyrighted work (such as URL where the work is
          lawfully posted or the page numbers or portions of the copyrighted
          work) and the identification of specific material on our Website that
          you claim is infringing the copyrighted work, including the location
          of the material to be removed with a URL or other specific
          identification.
        </TheTextSec>
        <TheTextSec>
          1. Information for us to contact you, such as an address, telephone
          number, and, an electronic mail address.
        </TheTextSec>
        <TheTextSec>
          2. A statement that you have a good faith belief that use of the
          material in the manner complained of is not authorized by the
          copyright owner, its agent, or the law, and a statement that the
          information in the notification is accurate, and under penalty of
          perjury, that you are the copyright owner or authorized to act on
          behalf of the copyright owner.
        </TheTextSec>
        <TheTextSec>
          3. Trademark. You warrant that any trademark you use is not infringing
          on the trademarks of others and is not confusingly similar to the
          trademarks of others whether or not those trademarks are registered
          locally or nationally. If you believe that someone has violated your
          trademark rights or any other intellectual property rights you may
          send us a notice of trademark infringement to same.
        </TheTextSec>
        <AtTextFild>
          9. Information and Materials You Post or Provide
        </AtTextFild>
        <TheTextSec>
          You represent that you have all right, title, and interest to
          materials you post on the Truckifix Platform or provide to
          Truckifix(“Materials”), including but not limited to any consent,
          authorization, release, clearance or license from any third party
          (such as, but not limited to, any release related to rights of privacy
          or publicity) necessary for you to provide, post, upload, input or
          submit the Materials, and that posting such Materials does not violate
          or constitute the infringement of any patent, copyright, trademark,
          trade secret, right of privacy, right of publicity, moral rights, or
          other intellectual property right recognized by any applicable
          jurisdiction of any person or entity, or otherwise constitute the
          breach of any agreement with any other person or entity. You further
          represent and warrant that you are who you say you are, that you have
          not submitted fictitious, false or inaccurate information about
          yourself, and that all information contained in the posted Materials
          is true and your own work or work you are authorized to submit, and
          that the posted Materials do not contain any threatening, harassing,
          libelous, false, defamatory, offensive, obscene, or pornographic,
          material, or other material that would violate any other applicable
          law or regulation. You agree that you will not knowingly and with
          intent to defraud provide material and misleading information. You
          represent and warrant that the Materials you supply do not violate
          these Terms of Service.
        </TheTextSec>
        <AtTextFild>10. Links to Third-Party Platforms</AtTextFild>
        <TheTextSec>
          Links on the Truckifix Platform to third party Platforms or
          information are provided solely as a convenience to you. If you use
          these links, you will leave the Truckifix Platform. Such links do not
          constitute or imply an endorsement, sponsorship, or recommendation by
          Truckifix of the third party, the third-party Platform, or the
          information there. Truckifix is not responsible for the availability
          of any such Platforms. Truckifix is not responsible or liable for any
          such Platforms or the content thereon. If you use the links to the
          Platforms of Truckifix affiliates or service providers, you will leave
          the Truckifix Platform and will be subject to the Terms of Service and
          privacy policy applicable to those Platforms.
        </TheTextSec>
        <AtTextFild>11. Downloading Files</AtTextFild>
        <TheTextSec>
          Truckifix cannot and does not guarantee or warrant that files
          available for downloading through the Truckifix Platform will be free
          of infection by software viruses or other harmful computer code, files
          or programs.
        </TheTextSec>
        <AtTextFild>12. Disclaimers; Limitations of Liability</AtTextFild>
        <TheTextSec>
          Truckifix AND ITS SERVICE PROVIDERS, LICENSORS AND SUPPLIERS MAKE NO
          REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
          TIMELINESS, SECURITY OR ACCURACY OF THE Truckifix PLATFORM OR THE
          CONTENT FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
          LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICE AND RELATED
          GRAPHICS ARE PROVIDED “AS IS” WITHOUT WARRANTY OR CONDITION OF ANY
          KIND. Truckifix AND ITS SERVICE PROVIDERS, LICENSORS AND SUPPLIERS
          HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS OF ANY KIND WITH REGARD
          TO THE Truckifix PLATFORM AND THE CONTENT, INCLUDING ALL IMPLIED
          WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE AND NON-INFRINGEMENT. NO STATEMENT OR INFORMATION,
          WHETHER ORAL OR WRITTEN, OBTAINED FROM Truckifix IN ANY MEANS OR
          FASHION SHALL CREATE ANY WARRANTY NOT EXPRESSLY AND EXPLICITLY SET
          FORTH IN THIS AGREEMENT. THE CONTENT MAY INCLUDE INACCURACIES OR
          TYPOGRAPHICAL ERRORS.
        </TheTextSec>
        <TheTextSec>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          Truckifix AND ITS SERVICE PROVIDERS, LICENSORS OR SUPPLIERS BE LIABLE
          FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL, EXEMPLARY OR OTHER TYPE OF DAMAGES, INCLUDING WITHOUT
          LIMITATION DAMAGES FOR COVER OR LOSS OF USE, DATA, REVENUE OR PROFITS,
          ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF
          THE Truckifix PLATFORM, WITH THE DELAY OR INABILITY TO USE THE
          Truckifix PLATFORM, OR FOR ANY CONTENT, OR OTHERWISE ARISING OUT OF
          THE USE OF THE Truckifix PLATFORM, WHETHER BASED ON CONTRACT, TORT,
          NEGLIGENCE, STRICT LIABILITY, THE FAILURE OF ANY LIMITED REMEDY TO
          ACHIEVE ITS ESSENTIAL PURPOSE, OR OTHERWISE, EVEN IF Truckifix OR ANY
          OF Truckifix’S SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF
          DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION
          OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
          THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
        </TheTextSec>
        <TheTextSec>
          IF, NOTWITHSTANDING THE OTHER TERMS OF THIS AGREEMENT, Truckifix IS
          DETERMINED TO HAVE ANY LIABILITY TO YOU OR ANY THIRD PARTY FOR ANY
          LOSS, HARM OR DAMAGE, YOU AGREE THAT THE AGGREGATE LIABILITY OF
          TruckifixAND ITS OFFICERS, DIRECTORS, MANAGERS, EMPLOYEES, AFFILIATES,
          AGENTS, CONTRACTORS, SERVICE PROVIDERS, LICENSORS OR SUPPLIERS SHALL
          IN ALL CASES BE LIMITED TO ONE HUNDRED DOLLARS.
        </TheTextSec>
        <TheTextSec>
          IF A CLIENT OR OTHER USER IS DAMAGED BY A SERVICE PROVIDER, Truckifix
          IS NOT RESPONSIBLE FOR THE ACTION OR NEGLIGENCE OF A SERVICE PROVIDER.
          THE DAMAGES FROM THE SERVICE PROVIDER MUST BE RECOVERED DIRECTLY FROM
          THE SERVICE PROVIDER AND NOT FROM Truckifix.
        </TheTextSec>
        <AtTextFild>13. Indemnification</AtTextFild>
        <TheTextSec>
          You understand and agree that you are personally responsible for your
          behavior on the Truckifix Platform. You agree to indemnify, defend and
          hold harmless Truckifix, its parent companies, subsidiaries,
          affiliated companies, joint ventures, business partners, licensors,
          employees, agents, and any third-party information providers from and
          against all claims, losses, expenses, damages and costs (including,
          but not limited to, direct, incidental, consequential, exemplary and
          indirect damages), and reasonable attorneys’ fees, resulting from or
          arising out of your use, misuse, or inability to use the Truckifix
          Platform or the Content, or any violation by you of these Terms of
          Service.
        </TheTextSec>
        <AtTextFild>14. Privacy</AtTextFild>
        <TheTextSec>
          Your use of the Truckifix Platform is subject to Truckifix’s Privacy
          Policy, available at http://www.Truckifixservices.com/privacy/
        </TheTextSec>
        <AtTextFild>
          15. DISPUTE RESOLUTION AND ARBITRATION AGREEMENT DEFINITIONS
        </AtTextFild>
        <TheTextSec>
          The defined terms in the Terms of Service are also used in this
          Arbitration Agreement
        </TheTextSec>
        <TheTextSec>
          AAA refers to the American Arbitration Association
        </TheTextSec>
        <TheTextSec>
          AAA Rules refers to the Consumer Arbitration Rules that are in effect
          when{" "}
        </TheTextSec>
        <TheTextSec>
          arbitration is requested, as modified by the terms of this Arbitration
          Agreement.{" "}
        </TheTextSec>
        <TheTextSec>AAA Rules can be found at www.adr.org.</TheTextSec>
        <TheTextSec>
          Arbitration Agreement refers to this Dispute Resolution and
          Arbitration Agreement.
        </TheTextSec>
        <TheTextSec>
          Class Action Waiver is a part of the Arbitration Agreement that states
          that you waive the right to bring a class action lawsuit and that all
          disputes will be brought on an individual basis and not on behalf of a
          class, a representative basis or a collective action, with some
          exceptions as described below.
        </TheTextSec>
        <TheTextSec>
          Claim is a dispute between a Client, a Service Provider, or other user
          of the Truckifix Platform and Truckifix. Multiple disputes are
          referred to as Claims.
        </TheTextSec>
        <TheTextSec>
          FAA is the Federal Arbitration Act which governs the Arbitration
          Agreement.
        </TheTextSec>
        <TheTextSec>
          NLRB refers to the National Labor Relations Board PAGA refers to the
          Private Attorneys General Act of 2004, a California Law, California
          Labor Code § 2698 et seq., that allows private California citizens to
          bring a lawsuit that would normally only be brought by the California
          Attorney General.
        </TheTextSec>
        <TheTextSec>
          Representative PAGA Waiver is a part of this agreement in Section (c)
          where you waive your ability to bring a PAGA action in a
          representative capacity on behalf of others and agree to only use the
          ability to bring a claim under PAGA on your own behalf.
        </TheTextSec>
        <TheTextSec>
          Service Provider Claims are claims between Truckifix and a Service
          Provider or potential Service provider as defined in this Arbitration
          Agreement.
        </TheTextSec>
        <TheTextSec>(a) Mutual Agreement to Arbitrate.</TheTextSec>
        <TheTextSec>
          ANY ARBITRATION UNDER THE TERMS OF SERVICE WILL BE ON AN INDIVIDUAL
          BASIS AND NOT AS A CLASS. CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT
          PERMITTED UNDER THIS AGREEMENT.
        </TheTextSec>
        <TheTextSec>
          To the fullest extent allowed by law, any controversy, claim or
          dispute (“Claim”) between you and Truckifix (and/or any of its
          affiliates, owners, shareholders, directors, officers, employees,
          volunteers or agents) relating to or arising out of the Terms of
          Service Agreement or any goods or services provided by Truckifix will
          be submitted to final and binding arbitration in Bexar County, Texas,
          for determination in accordance with the AAA Consumer Arbitration
          Rules (the “AAA Rules”), including any subsequent modifications or
          amendments to such Rules, as the exclusive remedy for such Claim. In
          any such arbitration, the parties may conduct discovery to the same
          extent as would be permitted in a court of law. You acknowledge that
          you have had the opportunity to read and review the AAA Rules prior to
          agreeing to be bound by this Agreement. A copy of the Rules is
          available at www.adr.org. The arbitrator shall issue a written
          decision and shall have full authority to award all remedies which
          would be available in court. Truckifix shall pay the arbitrators fees
          and any AAA administrative expenses. Any judgment upon the award
          rendered by the arbitrator may be entered in any court having
          jurisdiction thereof. Possible disputes covered by the above include
          (but are not limited to) unpaid fees, breach of contract (including
          this Agreement), torts, violation of public policy, discrimination,
          harassment, or any other claims under laws including but not limited
          to, Title VII of the Civil Rights Act of 1964, the Americans With
          Disabilities Act, the California Labor Code and any other statutes,
          regardless of whether such dispute is initiated by you or Truckifix.
          Thus, this Arbitration Agreement fully applies to any and all claims
          that Truckifix may have against you, including (but not limited to)
          claims for misappropriation of Truckifix property, disclosure of
          proprietary information or trade secrets, interference with contract,
          trade libel, gross negligence, or any other claim for alleged wrongful
          conduct or breach of the duties under this Agreement. Any dispute
          regarding whether a Claim or Claims are subject to arbitration will be
          decided by the Arbitrator. BY AGREEING TO BINDING ARBITRATION YOU
          UNDERSTAND THAT YOU AND Truckifix ARE WAIVING THE RIGHT TO SUE IN
          COURT OR HAVE A JURY TRIAL FOR ALL CLAIMS, EXCEPT AS EXPRESSLY
          OTHERWISE PROVIDED IN THIS ARBITRATION AGREEMENT.
        </TheTextSec>
        <TheTextSec>(b) Class Action Waiver. </TheTextSec>
        <TheTextSec>
          . You and Truckifix agree that (i) no arbitration proceeding to
          resolve a Claim or Claims under this Agreement shall be certified as a
          class action or proceed as a class action, or on a basis involving
          claims brought in a purported representative capacity on behalf of the
          general public, other customers or potential customers or persons
          similarly situated, and (ii) no arbitration proceeding hereunder shall
          be consolidated with, or joined in any way with, any other arbitration
          proceeding. THE PARTIES AGREE TO ARBITRATE A CLAIM ON AN INDIVIDUAL
          BASIS AND EACH WAIVES THE RIGHT TO PARTICIPATE IN A CLASS ACTION. THIS
          SUBSECTION (b) SHALL NOT APPLY TO REPRESENTATIVE PAGA (PRIVATE
          ATTORNEYS GENERAL ACT) CLAIMS BROUGHT AGAINST Truckifix, WHICH ARE
          ADDRESSED SEPARATELY IN SUBSECTION (c).
        </TheTextSec>
        <TheTextSec>(c) Representative PAGA Waiver. </TheTextSec>
        <TheTextSec>
          To the extent permitted by law, You agree to waive any right to bring
          a PAGA claim as a representative of others, and that any PAGA claim
          that you bring will be in an individual capacity. You further agree
          that any claim under private attorney general basis including PAGA
          will be resolved in binding arbitration. If any provision of this
          Representative PAGA Waiver is found to be unenforceable or unlawful
          for any reason, the unenforceable or unlawful portion or provision
          will be struck from this Arbitration Agreement and will not impact the
          validity of this Arbitration Agreement including the agreement to
          arbitrate all other Claims. If a court or an arbitrator determines
          that this Representative PAGA Waiver is unenforceable, and this
          results in Claims to be litigated in a court of law, the parties agree
          to stay the proceedings in the court of law until a written opinion is
          issued by an arbitrator for any related parties or claims in
          arbitration.
        </TheTextSec>
        <TheTextSec>(d) Arbitration Rules. </TheTextSec>
        <TheTextSec>
          Any arbitration under this Arbitration Agreement will be conducted by
          the AAA under the Consumer Arbitration Rules. The parties to an
          arbitration may mutually decide to use a different set of arbitration
          rules. The arbitrator will decide all Claims on an individual basis
          based on the applicable state or federal laws. The arbitrator shall
          not be bound by prior arbitrations involving different Clients or
          Service Providers but will be bound by prior arbitrations involving
          the same Clients or the same Service Providers according to applicable
          laws.
        </TheTextSec>
        <TheTextSec>(e) Arbitration Fees and Awards. </TheTextSec>
        <TheTextSec>
          Each party to arbitration will pay the Arbitration Fees according to
          the AAA Rules. Each party to arbitration will pay their own attorney
          fees and costs for the arbitration, except as provided in Federal Rule
          of Civil Procedure 68 or any state equivalents. The arbitrator may
          award reasonable fees and costs or a portion of fees and costs to the
          prevailing party as part of the arbitration award, to the extent
          authorized by applicable law. If you choose to participate in the
          Optional Negotiation Process Prior to Arbitration set out in section
          (j) of this Arbitration Agreement by submitting a Claim through the
          negotiation process outlined, and then file arbitration for the same
          Claim under the AAA Rules, Truckifix will reimburse you for all but
          $50 of the fees required to file the arbitration claim. But if the
          arbitrator finds that the Claim brought is for an improper purpose or
          contains frivolous arguments that are not supported by evidence
          (according to the standard in Federal Rule of Civil Procedure 11(b)),
          then the payment of the arbitration filing fee will be according to
          the AAA Rules and you will not receive a reimbursement of your
          arbitration filing fees according to this provision.
        </TheTextSec>
        <TheTextSec>(f) Location and Type of Arbitration. </TheTextSec>
        <TheTextSec>
          The arbitration will take place in Morgan County, Alabama. If You file
          an arbitration Claim that is less than $10,000 you may choose Desk
          Arbitration (paper submissions only), In-Person or Telephonic Hearing
          with a single arbitrator according to the AAA Rules. If Your claim is
          greater than $10,000, the AAA Rules will govern whether a hearing will
          take place as part of the arbitration.
        </TheTextSec>
        <TheTextSec>(g) Exceptions to Arbitration</TheTextSec>
        <TheTextSec>
          This Arbitration Agreement will not require arbitration of claims for
          workers’ compensation, state disability insurance and unemployment
          insurance benefits and claims that may not be subject to arbitration
          as a matter of law not preempted by the FAA. This Arbitration
          Agreement does not apply to any reports that You choose to make to a
          local, state or federal authority such as the Equal Employment
          Opportunity Commission, U.S. Department of Labor, Securities and
          Exchange Commission, National Labor Relations Board (“NLRB”) or Office
          of Federal Contract Compliance Programs, and nothing in this
          Arbitration Agreement will preclude a party from bringing an
          administrative claim before an agency to fulfill a requirement to
          exhaust administrative remedies prior to bringing a Claim in
          arbitration. This Arbitration Agreement does not prevent you from
          participating in an investigation of a report, claim or charge by a
          government agency.
        </TheTextSec>
        <TheTextSec>(h) Severability</TheTextSec>
        <TheTextSec>
          If any provision of this Arbitration Agreement is found to be
          unenforceable or unlawful for any reason, the unenforceable or
          unlawful portion or provision will be struck from this Arbitration
          Agreement and will not impact the validity of this Arbitration
          Agreement including the agreement to arbitrate all other Claims, and
          the remainder of this Arbitration Agreement will be given full force
          and effect.
        </TheTextSec>
        <TheTextSec>
          (i) Opting Out of Arbitration for Service Providers.{" "}
        </TheTextSec>
        <TheTextSec>
          Service Provider Claims include Claims brought by a service provider
          against Truckifix that: (1) are based on an alleged employment
          relationship between Truckifix and the Service Provider; (2) relate to
          Truckifix’s deactivation or threatened deactivation of a Service
          Provider’s Truckifix account; (3) relate to Truckifix’s termination or
          threatened termination of a relationship with Service Provider; (4)
          relate to Claims of compensation for services owed by Truckifix to
          Service Provider; or (5) relate to background checks performed by
          Truckifix to determine Service Provider’s eligibility to become or
          remain a Service Provider. Service Provider in this provision includes
          current and prospective Service Providers. Service Providers may opt
          out of the requirement to arbitrate Service Provider Claims by
          notifying Mach 1 in writing of your desire to opt out of required
          arbitration for Service Provider Claims.{" "}
        </TheTextSec>
        <TheTextSec>
          The notice to opt out must be signed and delivered by: (A) email{" "}
        </TheTextSec>
        <TheTextSec>
          to info@truckifix.com or (B) by certified mail, postage prepaid and
          return receipt requested, or by a major national delivery service with
          delivery confirmation (such as FedEx, UPS, etc.) that is addressed to:
        </TheTextSec>
        <TheTextSec>
          The written notice must also clearly state your intent to opt out of
          mandatory arbitration for Service Provider Claims and must state the
          name, phone number, and email address associated with the Service
          Provider account. The written notice must be sent within 30 days of
          the signing or agreement to the Arbitration Agreement in this Terms of
          Service.
        </TheTextSec>
        <TheTextSec>
          You have the right to consult with an attorney regarding arbitration
          of Service Provider Claims and Truckifix will not discriminate against
          Service Providers who choose to opt out of arbitration for Service
          Provider Claims.
        </TheTextSec>
        <TheTextSec>
          (j) Optional Negotiation Process Prior to Arbitration.{" "}
        </TheTextSec>
        <TheTextSec>
          You and Truckifix may mutually choose to attempt negotiation of any
          Claim for 30 days before initiating arbitration under the AAA Rules.
          The parties may agree to extend the 30 day time limit for negotiation.
          To initiate a negotiation process the party seeking to recover based
          on a Claim will send a written notice to the other party that includes
          (1) the nature of the Claim and any known laws or regulations that the
          Claim is based on, and (2) the desired outcome of the claim including
          any specific monetary settlement amounts or other specific actions
          requested to settle the Claim. All information shared, offers,
          statements, whether oral or written, and all conduct observed by the
          parties including their agents, including attorneys and employees are
          confidential, privileged and inadmissible for any purpose including
          evidence of liability or for impeachment in an arbitration or other
          proceeding. Evidence that is otherwise discoverable and admissible
          will remain discoverable and admissible if obtained through proper
          discovery process under the AAA Rules or the rules of a proceeding.
        </TheTextSec>
        <AtTextFild>16. Entire Agreement/No Waiver. </AtTextFild>
        <TheTextSec>
          These Terms of Service constitute the entire agreement of the parties
          with respect to the subject matter of this Agreement. No waiver by
          Truckifix of any breach or default will be deemed to be a waiver of
          any preceding or subsequent breach or default.
        </TheTextSec>
        <AtTextFild>17. Correction of Errors and Inaccuracies. </AtTextFild>
        <TheTextSec>
          The Content may contain typographical errors or other errors or
          inaccuracies and may not be complete or current. Truckifix therefore
          reserves the right to correct any errors, inaccuracies or omissions
          and to change or update the Content at any time without prior notice.
          Truckifix does not, however, guarantee that any errors, inaccuracies
          or omissions will be corrected.
        </TheTextSec>
        <AtTextFild>
          18. Enforcement/ Choice of Law/ Choice of Forum.{" "}
        </AtTextFild>
        <TheTextSec>
          If any part of these Terms of Service is determined by an arbitrator
          or court of competent jurisdiction to be invalid or unenforceable, it
          will not impact any other provision of these Terms of Service, all of
          which will remain in full force and effect. Any and all disputes
          relating to these Terms of Service, Truckifix’s Privacy Policy, your
          use of the Truckifix Platform, any other Truckifix Platform or the
          Content are governed by, and will be interpreted in accordance with,
          the laws of the Commonwealth of Texas, without regard to any conflict
          of laws provisions. You agree to the sole and exclusive jurisdiction
          and venue of the federal or state courts in San Antonio, Texas in the
          event of any dispute of any kind arising from or relating to these
          Terms of Service, Truckifix’s Privacy Policy, your use of the
          Truckifix Platform, or any other Truckifix Platform or the Content.
        </TheTextSec>
        <TheTextSec>Last Revised: October 22, 2022</TheTextSec>
        <TheTextSec>
          Copyright 2018Truckifix, Incorporated Dba: Truckifix. All rights
          reserved
        </TheTextSec>
      </Box>
    </>
  );
};

export default TermsAndConditions;
