import React from "react";
import { Box, styled } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import AdbIcon from "@mui/icons-material/Adb";

const MainBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
  backgroundRepeat: "no-repeat, repeat",
  height: "600px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  color: "#fff",
  backgroundAttachment: "fixed",

  [theme.breakpoints.down("lg")]: {
    marginTop: "-100px",
    display: "block",
    height: "830px",
  },
}));

const TextBox = styled(Box)(({ theme }) => ({
  fontSize: "25px",
  wordWrap: "break-word",
  marginTop: "100px",
  marginLeft: "170px",
  letterSpacing: "2px",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
    wordWrap: "break-word",
    padding: "25px",
    marginTop: "100px",
    marginLeft: "30px",
    letterSpacing: "1px",
    fontWeight: "400",
  },
}));

const TextBoxGet = styled(Box)(({ theme }) => ({
  fontSize: "25px",
  marginTop: "10px",
  wordWrap: "break-word",
  marginLeft: "170px",
  letterSpacing: "2px",
  fontWeight: "600",
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
    marginTop: "-20px",
    wordWrap: "break-word",
    marginLeft: "50px",
    letterSpacing: "1px",
    fontWeight: "400",
  },
}));

const ImageNew = styled("img")(({ theme }) => ({
  width: "230px",
  height: "460px",
  marginLeft: "300px",
  marginTop: "75px",
  [theme.breakpoints.down("lg")]: {
    width: "200px",
    height: "400px",
    marginLeft: "95px",
    marginTop: "30px",
    overflow: "hidden",
  },
}));

const KartBox = styled(Box)(({ theme }) => ({
  width: "300px",
  height: "70px",
  background: "rgb(41, 57, 141)",
  marginLeft: "170px",
  marginTop: "15px",
  borderRadius: "8px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "45px",
  },
}));

const TypeTextBox = styled(Box)`
  margin-left: 17px;
  width: 100%;
  padding: 22px;
  font-size: 16px;
`;

const AndriodIcon = styled(Box)`
  margin-left: 235px;
  margin-top: -56px;
`;

const home = () => {
  return (
    <React.Fragment>
      <MainBox>
        <Box>
          <TextBox>The On-Demand Roadside Assistance</TextBox>
          <TextBoxGet>App, with no membership fee.</TextBoxGet>
          <KartBox>
            <TypeTextBox>(Customer) Get Help Now </TypeTextBox>
            <AndriodIcon>
              <AdbIcon style={{ fontSize: "40px" }} />
            </AndriodIcon>
          </KartBox>

          <KartBox>
            <TypeTextBox>(Customer) Get Help Now </TypeTextBox>
            <AndriodIcon>
              <AppleIcon style={{ fontSize: "40px" }} />
            </AndriodIcon>
          </KartBox>

          <KartBox>
            <TypeTextBox>(Provider) Download Now </TypeTextBox>
            <AndriodIcon>
              <AdbIcon style={{ fontSize: "40px" }} />
            </AndriodIcon>
          </KartBox>
        </Box>
        <ImageNew src="Image/89354.png" alt="" />
      </MainBox>
    </React.Fragment>
  );
};

export default home;
