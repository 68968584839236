import React from "react";
import { Link } from "react-router-dom";
import { Box, styled } from "@mui/material";

const MainBox = styled(Box)(({ theme }) => ({
  marginLeft: "250px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "20px",
  },
}));

const ImageFont = styled("img")(({ theme }) => ({
  width: "150px",
  height: "70px",
  marginLeft: "150px",
  cursor: "pointer",
  [theme.breakpoints.down("lg")]: {
    width: "100px",
    height: "50px",
    marginLeft: "10px",
  },
}));

const House = () => {
  return (
    <nav className="navbar sticky-top  navbar-expand-lg navbar-light bg-light">
      <Link to="/">
        <ImageFont src="Image/RX1.png" alt="" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <MainBox>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item dropdown">
              <Link
                className="nav-link"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                SERVICES
              </Link>
              <div className="dropdown-menu " aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to="/flat">
                  FLAT TIRE
                </Link>
                <Link className="dropdown-item" to="/towtruck">
                  TOW TRUCK
                </Link>
                <Link className="dropdown-item" to="/deadbatter">
                  DEAD BATTERY
                </Link>
                <Link className="dropdown-item" to="/outfull">
                  OUT OF FULL
                </Link>
                <Link className="dropdown-item" to="/mobile">
                  MOBILE MECHANIC
                </Link>
                <Link className="dropdown-item" to="/lock">
                  LOCK OUT
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link "
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                LOCATIONS
              </Link>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to="/location">
                  ALL LOCATIONS
                </Link>
              </div>
            </li>

            <li className="nav-item">
              <Link className="nav-link " to="/about">
                ABOUT
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link " to="/industry">
                INDUSTRY SOLUTIONS
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                SERVICE PROVIDERS
              </Link>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to="/whothe">
                  WHO THEY ARE
                </Link>
                <Link className="dropdown-item" to="/becomeservice">
                  BECOME A SERVICE PROVIDER
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link " to="/contactpage">
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </MainBox>
    </nav>
  );
};

export default House;
