import React from "react";
import House from "../Header/House";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AppleIcon from "@mui/icons-material/Apple";
import AdbIcon from "@mui/icons-material/Adb";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import { styled, Box, Typography, Button } from "@mui/material";

const MainBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
  backgroundRepeat: "no-repeat, repeat",
  height: "400px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  color: "#fff",
  backgroundAttachment: "fixed",
  [theme.breakpoints.down("lg")]: {
    marginTop: "-100px",
    display: "block",
    height: "100px",
  },
}));

const TextMain = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: "54px",
  lineHeight: "1.2",
  fontWeight: "600",
  textAlign: "center",
  marginLeft: "465px",
  marginTop: "150px",
  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
    marginLeft: "-15px",
    marginTop: "100px",
    fontSize: "30px",
    padding: "26px",
  },
}));

const OmaxBox = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("lg")]: {
    display: "block",
  },
}));

const Image = styled("img")(({ theme }) => ({
  height: "500px",
  Width: "500px",
  marginLeft: "150px",
  [theme.breakpoints.down("lg")]: {
    width: "300px",
    height: "180px",
    marginLeft: "40px",
    marginTop: "20px",
  },
}));

const ThirdBox = styled(Box)(({ theme }) => ({
  width: "84%",

  marginLeft: "150px",
  marginRight: "180px",
  marginTop: "30px",
  marginButtom: "30px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "35px",
    marginRight: "0px",
    marginTop: "30px",
    marginButtom: "0px",
  },
}));

const HowText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  position: "relative",
  display: "table-cell",
  verticalAlign: "middle",
  textAlign: "left",
  padding: "5px 0",

  [theme.breakpoints.down("lg")]: {
    fontSize: "17px",
    fontWeight: "600",
  },
}));

const PriceText = styled(Typography)(({ theme }) => ({
  paddingLeft: "20px",
  width: "100%",
  color: "#454545",
  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
    fontWeight: "500",
  },
}));

const WnatText = styled(Typography)(({ theme }) => ({
  color: "#29398d",
  fontSize: "32px",
  lineHeight: "60px",
  fontWeight: "500",
  textTransform: "none",
  marginLeft: "80px",
  marginTop: "40px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
    fontWeight: "600",
    marginLeft: "60px",
    marginTop: "10px",
  },
}));

const SenodWantText = styled(Typography)(({ theme }) => ({
  width: "400px",
  fontSize: "16px",
  color: "#454545",

  lineHeight: "28px",
  marginLeft: "90px",
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    textAlign: "center",
    marginLeft: "20px",
    marginTop: "-5px",
    fontSize: "15px",
    fontWeight: "500",
  },
}));

const ButtomBox = styled(Box)(({ theme }) => ({
  marginLeft: "80px",
  marginTop: "50px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "25px",
    marginTop: "15px",
    fontWeight: "600",
  },
}));

const CustomerText = styled(Typography)(({ theme }) => ({
  color: "#29398d",
  fontFamily: "'Raleway', sans-serif",
  fontSize: "19px",
  lineHeight: "24px",
  fontWeight: "600",
  marginTop: "40px",
  marginLeft: "180px",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "-15px",
  },
}));

const KartBox = styled(Box)(({ theme }) => ({
  width: "300px",
  height: "70px",
  background: "rgb(41, 57, 141)",
  marginLeft: "130px",
  marginTop: "15px",
  borderRadius: "8px",
  marginBottom: "25px",
  cursor: "pointer",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "45px",
  },
}));

const TypeTextBox = styled(Box)`
  margin-left: 17px;
  width: 100%;
  padding: 22px;
  font-size: 16px;
  color: white;
`;

const AndriodIcon = styled(Box)`
  color: white;
  margin-left: 235px;
  margin-top: -56px;
`;

const BackGif = styled(Box)(({ theme }) => ({
  background:
    'lightblue url("https://media.tenor.com/aV0h3VAHWUAAAAAC/gradient.gif") center',
  width: "400px",
  height: "120px",
  marginBottom: "40px",
  marginLeft: "85px",
  marginTop: "50px",
  cursor: "pointer",
  textDecoration: "none",
  [theme.breakpoints.down("lg")]: {
    width: "300px",
    height: "120px",
    marginLeft: "43px",
  },
}));

const DooText = styled(Typography)`
  font-size: 24px;
  color: #fff;
  padding: 12px;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  line-height: 1;
`;

const LoaclIcon = styled(Box)`
  text-decoration: none;
  color: white;
  text-align: center;
`;

const Location = () => {
  return (
    <>
      <House />
      <MainBox>
        <Box>
          <TextMain>Truckifix Locations</TextMain>
        </Box>
      </MainBox>
      <OmaxBox>
        <Box>
          <Image
            src="https://www.mach1services.com/wp-content/uploads/2021/04/9FBFB8AD-2AD7-4BE5-9E33-7E2CEFA8017A.gif"
            alt=""
          />

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>ALABAMA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Albertville</PriceText>
                <PriceText>Anniston</PriceText>
                <PriceText>Arab</PriceText>
                <PriceText>Athens</PriceText>
                <PriceText>Birmingham</PriceText>
                <PriceText>Calera</PriceText>
                <PriceText>Chickasaw</PriceText>
                <PriceText>Cullman</PriceText>
                <PriceText>Dadeville</PriceText>
                <PriceText>Danville</PriceText>
                <PriceText>Decatur</PriceText>
                <PriceText>Empire</PriceText>
                <PriceText>Foley</PriceText>
                <PriceText>Fort Deposit</PriceText>
                <PriceText>Guntersville</PriceText>
                <PriceText>Haleyville</PriceText>
                <PriceText>Hoover</PriceText>
                <PriceText>Hueytown</PriceText>
                <PriceText>Huntsville</PriceText>
                <PriceText>Irondale</PriceText>
                <PriceText>Lanett</PriceText>
                <PriceText>Linden</PriceText>
                <PriceText>Mobile</PriceText>
                <PriceText>Montgomery</PriceText>
                <PriceText>Notasulga</PriceText>
                <PriceText>Oneonta</PriceText>
                <PriceText>Opelika</PriceText>
                <PriceText>Phenix City</PriceText>
                <PriceText>Semmes</PriceText>
                <PriceText>Talladega</PriceText>
                <PriceText>Theodore</PriceText>
                <PriceText>Trinity</PriceText>
                <PriceText>Tuscaloosa</PriceText>
                <PriceText>Uniontown</PriceText>
                <PriceText>Warrior</PriceText>
                <PriceText>Wilmer</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>
          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>ALASKA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Anchorage</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>
          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>ARIZONA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Apache Junction</PriceText>
                <PriceText>Avondale</PriceText>
                <PriceText>Camp Verde</PriceText>
                <PriceText>Casa Grande</PriceText>
                <PriceText>Gilbert</PriceText>
                <PriceText>Glendale</PriceText>
                <PriceText>Goodyear</PriceText>
                <PriceText>Maricopa</PriceText>
                <PriceText>Mesa</PriceText>
                <PriceText>Peoria</PriceText>
                <PriceText>Phoenix</PriceText>
                <PriceText>Prescott</PriceText>
                <PriceText>Tempe</PriceText>
                <PriceText>Tenopah</PriceText>
                <PriceText>Tucson</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>ARKANSAS</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Berryville</PriceText>
                <PriceText>Cabot</PriceText>
                <PriceText>Centerton</PriceText>
                <PriceText>Conway</PriceText>
                <PriceText>El Dorado</PriceText>
                <PriceText>Fayetteville</PriceText>
                <PriceText>Heber Springs</PriceText>
                <PriceText>Hindsville</PriceText>
                <PriceText>Horseshoe Bend</PriceText>
                <PriceText>Hot Springs</PriceText>
                <PriceText>Jacksonville</PriceText>
                <PriceText>Jonesboro</PriceText>
                <PriceText>Little Rock</PriceText>
                <PriceText>Marion</PriceText>
                <PriceText>Paragould</PriceText>
                <PriceText>Pine Bluff</PriceText>
                <PriceText>Rockport</PriceText>
                <PriceText>Siloam Springs</PriceText>
                <PriceText>Stuttgart</PriceText>
                <PriceText>Van Buren</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>CALIFORNIA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText> Alameda </PriceText>

                <PriceText>Anaheim</PriceText>

                <PriceText> Antelope </PriceText>

                <PriceText> Antioch </PriceText>

                <PriceText> Apple Valley </PriceText>

                <PriceText> Bakersfield </PriceText>

                <PriceText> Baldwin Park </PriceText>

                <PriceText> Barstow </PriceText>

                <PriceText> Beaumont </PriceText>

                <PriceText> Blythe </PriceText>

                <PriceText> Brentwood</PriceText>

                <PriceText> Buena Park</PriceText>

                <PriceText> Capetown</PriceText>

                <PriceText> California City </PriceText>

                <PriceText> Cathedral City</PriceText>

                <PriceText> Chico</PriceText>

                <PriceText> Chula Vista</PriceText>

                <PriceText> Coachella</PriceText>

                <PriceText> Compton</PriceText>

                <PriceText> Corona</PriceText>

                <PriceText> Cupertino</PriceText>

                <PriceText> Cypress</PriceText>

                <PriceText> Davis</PriceText>

                <PriceText> Downey</PriceText>

                <PriceText> Dublin</PriceText>

                <PriceText> El Cajon</PriceText>

                <PriceText> Elk Grove</PriceText>

                <PriceText> Escondido</PriceText>

                <PriceText> Fairfield</PriceText>

                <PriceText> Folsom</PriceText>

                <PriceText> Fontana</PriceText>

                <PriceText> Fremont</PriceText>

                <PriceText> Fresno</PriceText>

                <PriceText> Galt</PriceText>

                <PriceText> Garden Grove</PriceText>

                <PriceText> Gardena</PriceText>

                <PriceText> Gilroy</PriceText>

                <PriceText> Glendale</PriceText>

                <PriceText> Glendora</PriceText>

                <PriceText> Hanford</PriceText>

                <PriceText> Hawthorne</PriceText>

                <PriceText> Hayward</PriceText>

                <PriceText> Hemet</PriceText>

                <PriceText> Hesperia</PriceText>

                <PriceText> Huntington Beac </PriceText>

                <PriceText> Imperial</PriceText>

                <PriceText> Inglewood</PriceText>

                <PriceText> Irvine</PriceText>

                <PriceText> Laguna Hills</PriceText>

                <PriceText> Laguna Niguel</PriceText>

                <PriceText> Lancaster</PriceText>

                <PriceText> Lebec</PriceText>

                <PriceText> Livermore</PriceText>

                <PriceText> Lodi</PriceText>

                <PriceText> Long Beach</PriceText>

                <PriceText> Los Angeles</PriceText>

                <PriceText> Madera</PriceText>

                <PriceText> Menifee</PriceText>

                <PriceText> Millbrae</PriceText>

                <PriceText> Modesto</PriceText>

                <PriceText> Monrovia</PriceText>

                <PriceText> Moreno Valley</PriceText>

                <PriceText> Montclair</PriceText>

                <PriceText> Morgan Hill</PriceText>

                <PriceText> Needles</PriceText>

                <PriceText> Oakhurst</PriceText>

                <PriceText> Oakland</PriceText>

                <PriceText> Oceanside</PriceText>

                <PriceText> Ontario</PriceText>

                <PriceText> Orange</PriceText>

                <PriceText> Oxnard</PriceText>

                <PriceText> Palmdale</PriceText>

                <PriceText> Pittsburg</PriceText>

                <PriceText> Placentia</PriceText>

                <PriceText> Placerville</PriceText>

                <PriceText> Rancho Cucamong </PriceText>

                <PriceText> Riverside</PriceText>

                <PriceText> Sacramento</PriceText>

                <PriceText> Salinas</PriceText>

                <PriceText> San Bernardino</PriceText>

                <PriceText> San Bruno</PriceText>

                <PriceText> San Carlos</PriceText>

                <PriceText> San Diego</PriceText>

                <PriceText> San Francisco</PriceText>

                <PriceText> San Jacinto</PriceText>

                <PriceText> San Jose</PriceText>

                <PriceText> San Luis Obispo </PriceText>

                <PriceText> San Rafael</PriceText>

                <PriceText> Santa Ana</PriceText>

                <PriceText> Santa Clara</PriceText>

                <PriceText> Santa Maria</PriceText>

                <PriceText> Santa Monica</PriceText>

                <PriceText> Santa Rosa</PriceText>

                <PriceText> Simi Valley</PriceText>

                <PriceText> Sonoma</PriceText>

                <PriceText> Stockton</PriceText>

                <PriceText> Temecula</PriceText>

                <PriceText> Temescal Valley </PriceText>

                <PriceText> Thousand Oaks</PriceText>

                <PriceText> Torrance</PriceText>

                <PriceText> Tracy</PriceText>

                <PriceText> Tulare</PriceText>

                <PriceText> Upland</PriceText>

                <PriceText> Van Nuys</PriceText>

                <PriceText> Vallejo</PriceText>

                <PriceText> Ventura</PriceText>

                <PriceText> Visalia</PriceText>

                <PriceText> Vista</PriceText>

                <PriceText> Watsonville</PriceText>

                <PriceText> West Hollywood</PriceText>

                <PriceText> Woodbridge</PriceText>

                <PriceText> Woodland</PriceText>

                <PriceText> Yorba Linda</PriceText>

                <PriceText> Yuba City</PriceText>

                <PriceText> Yucca Valley</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>DELAWARE</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Bridgeville</PriceText>

                <PriceText> Dover</PriceText>

                <PriceText> Lewes</PriceText>

                <PriceText> Middletown</PriceText>

                <PriceText> Millsboro</PriceText>

                <PriceText> New Castle</PriceText>

                <PriceText> Newark</PriceText>

                <PriceText> Seaford</PriceText>

                <PriceText> Smyrna</PriceText>

                <PriceText>Wilmington </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>FLORIDA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText> Boca Raton</PriceText>

                <PriceText> Bonita Springs</PriceText>

                <PriceText> Boynton Beach</PriceText>

                <PriceText> Brooksville</PriceText>

                <PriceText> Cape Coral</PriceText>

                <PriceText> Chipley</PriceText>

                <PriceText> Clearwater</PriceText>

                <PriceText> Clermont</PriceText>

                <PriceText> Cocoa</PriceText>

                <PriceText> Coconut Creek</PriceText>

                <PriceText> Crawfordville</PriceText>

                <PriceText> Dade City</PriceText>

                <PriceText> Davenport</PriceText>

                <PriceText> Daytona Beach</PriceText>

                <PriceText> Deltona</PriceText>

                <PriceText> Destin</PriceText>

                <PriceText> Fort Lauderdale</PriceText>

                <PriceText> Fort Myers</PriceText>

                <PriceText> Fort Walton</PriceText>

                <PriceText> Gainesville</PriceText>

                <PriceText> Glen Saint Mary</PriceText>

                <PriceText> Greenacres</PriceText>

                <PriceText> Groveland</PriceText>

                <PriceText> Haines City</PriceText>

                <PriceText> Hallandale Beach</PriceText>

                <PriceText> Hialeah</PriceText>

                <PriceText> Holly Hill</PriceText>

                <PriceText> Hollywood</PriceText>

                <PriceText> Homestead</PriceText>

                <PriceText> Hudson</PriceText>

                <PriceText> Inverness</PriceText>

                <PriceText> Jacksonville</PriceText>

                <PriceText> Jupiter</PriceText>

                <PriceText> Kissimmee</PriceText>

                <PriceText> LaBelle</PriceText>

                <PriceText> Lake City</PriceText>

                <PriceText> Lake Worth</PriceText>

                <PriceText> Lakeland Lauderdale Lakes</PriceText>

                <PriceText> Lehigh Acres</PriceText>

                <PriceText> Loxahatchee</PriceText>

                <PriceText> Madison</PriceText>

                <PriceText> Marathon</PriceText>

                <PriceText> Melbourne</PriceText>

                <PriceText> Miami Beach</PriceText>

                <PriceText> Miami Gardens</PriceText>

                <PriceText> Miami</PriceText>

                <PriceText> Middleburg</PriceText>

                <PriceText> Milton</PriceText>

                <PriceText> Miramar</PriceText>

                <PriceText> Naples</PriceText>

                <PriceText> New Port Richey</PriceText>

                <PriceText> North Lauderdale</PriceText>

                <PriceText> North Port</PriceText>

                <PriceText> Ocala</PriceText>

                <PriceText> Oldsmar</PriceText>

                <PriceText> Orlando</PriceText>

                <PriceText> Oviedo</PriceText>

                <PriceText> Palm Bay</PriceText>

                <PriceText> Palm Harbor</PriceText>

                <PriceText> Panama City</PriceText>

                <PriceText> Parrish</PriceText>

                <PriceText> Pensacola Beach</PriceText>

                <PriceText> Pensacola</PriceText>

                <PriceText> Pinellas County</PriceText>

                <PriceText> Pompano Beach</PriceText>

                <PriceText> Port Charlotte</PriceText>

                <PriceText> Port Orange</PriceText>

                <PriceText> Port Richey</PriceText>

                <PriceText>Quincy</PriceText>

                <PriceText> Rockledge</PriceText>

                <PriceText> Saint Petersburg</PriceText>

                <PriceText>Sarasota</PriceText>

                <PriceText>Silver Springs</PriceText>

                <PriceText>Spring Hill</PriceText>

                <PriceText>St. Lucie</PriceText>

                <PriceText>Summerfield</PriceText>

                <PriceText>Tallahassee</PriceText>

                <PriceText>Tampa</PriceText>

                <PriceText> The Villages</PriceText>

                <PriceText>Valrico</PriceText>

                <PriceText> Wahneta</PriceText>

                <PriceText>West Palm Beach</PriceText>

                <PriceText> Windermere</PriceText>

                <PriceText> Winter Haven</PriceText>

                <PriceText> Winter Park</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>GEORGIA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Acworth</PriceText>

                <PriceText> Adairsville</PriceText>

                <PriceText> Albany</PriceText>

                <PriceText> Atlanta</PriceText>

                <PriceText> Augusta</PriceText>

                <PriceText> Austell</PriceText>

                <PriceText> Braselton</PriceText>

                <PriceText> Brunswick</PriceText>

                <PriceText> Buford</PriceText>

                <PriceText> Calhoun</PriceText>

                <PriceText> Cartersville</PriceText>

                <PriceText> Chamblee</PriceText>

                <PriceText> College park</PriceText>

                <PriceText> Columbus</PriceText>

                <PriceText> Covington</PriceText>

                <PriceText> Dallas</PriceText>

                <PriceText> Decatur</PriceText>

                <PriceText> DeKalb</PriceText>

                <PriceText> Douglas</PriceText>

                <PriceText> Douglasville</PriceText>

                <PriceText> Dublin</PriceText>

                <PriceText> Duluth</PriceText>

                <PriceText> Elljay</PriceText>

                <PriceText> Evans</PriceText>

                <PriceText> Fairburn</PriceText>

                <PriceText> Fayetteville</PriceText>

                <PriceText> Gainesville</PriceText>

                <PriceText> Griffin</PriceText>

                <PriceText> Hampton</PriceText>

                <PriceText> Hinesville</PriceText>

                <PriceText> Hiram</PriceText>

                <PriceText> Jonesboro</PriceText>

                <PriceText> Lavonia</PriceText>

                <PriceText> Lawrenceville</PriceText>

                <PriceText> Lilburn</PriceText>

                <PriceText> Lithia Springs</PriceText>

                <PriceText> Lithonia</PriceText>

                <PriceText> Loganville</PriceText>

                <PriceText> Macon</PriceText>

                <PriceText> Madison</PriceText>

                <PriceText> Marietta</PriceText>

                <PriceText> Maysville</PriceText>

                <PriceText> McDonough</PriceText>

                <PriceText> Morrow</PriceText>

                <PriceText> Norcross</PriceText>

                <PriceText> Powder Springs</PriceText>

                <PriceText> Ringgold</PriceText>

                <PriceText> Rome</PriceText>

                <PriceText> Savannah</PriceText>

                <PriceText> Snellville</PriceText>

                <PriceText> Stockbridge</PriceText>

                <PriceText> Stone Mountain</PriceText>

                <PriceText> Talking Rock</PriceText>

                <PriceText> Tucker</PriceText>

                <PriceText> Valdosta</PriceText>

                <PriceText> Warner Robins </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>HAWAII</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Honolulu</PriceText>

                <PriceText>Kamuela</PriceText>

                <PriceText>Lihue</PriceText>

                <PriceText> Wahiawa</PriceText>

                <PriceText> Wailuku</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>IDAHO</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Boise</PriceText>

                <PriceText> Caldwell</PriceText>

                <PriceText> Garden City</PriceText>

                <PriceText> Idaho Falls</PriceText>

                <PriceText> Lewiston</PriceText>

                <PriceText> Nampa</PriceText>

                <PriceText> Victor</PriceText>

                <PriceText> Winchester</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>ILLINOIS</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Addison</PriceText>

                <PriceText> Alsip</PriceText>

                <PriceText> Aurora</PriceText>

                <PriceText> Batavia</PriceText>

                <PriceText> Belleville</PriceText>

                <PriceText> Benton</PriceText>

                <PriceText> Calumet Park</PriceText>

                <PriceText> Chicago</PriceText>

                <PriceText> Danville</PriceText>

                <PriceText> Des Plaines</PriceText>

                <PriceText> Elgin </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>
          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>INDIANA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Anderson</PriceText>

                <PriceText> Beech Grove</PriceText>

                <PriceText> Bloomington</PriceText>

                <PriceText> Carmel</PriceText>

                <PriceText> Columbus</PriceText>

                <PriceText> Elkhart</PriceText>

                <PriceText> Fort Wayne</PriceText>

                <PriceText> Gary</PriceText>

                <PriceText> Hammond</PriceText>

                <PriceText> Hobart</PriceText>

                <PriceText> Indianapolis</PriceText>

                <PriceText> Kokomo</PriceText>

                <PriceText> Lafayette</PriceText>

                <PriceText> Madison</PriceText>

                <PriceText> Noblesville</PriceText>

                <PriceText> Oakland City</PriceText>

                <PriceText> Plymouth</PriceText>

                <PriceText> Salem</PriceText>

                <PriceText> South Bend</PriceText>

                <PriceText> Terre Haute</PriceText>

                <PriceText> Valparaiso </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>IOWA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Bellevue</PriceText>

                <PriceText> Bouton</PriceText>

                <PriceText> CarlisleV</PriceText>

                <PriceText> Carter Lake</PriceText>

                <PriceText> Clearfield</PriceText>

                <PriceText> Clive</PriceText>

                <PriceText> Council Bluffs</PriceText>

                <PriceText> Des Moines</PriceText>

                <PriceText> Donnellson</PriceText>

                <PriceText> Garner</PriceText>

                <PriceText> Iowa City</PriceText>

                <PriceText> Osceola</PriceText>

                <PriceText> Ottumwa</PriceText>

                <PriceText> Sac City</PriceText>

                <PriceText> Sioux City</PriceText>

                <PriceText> Waterloo </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>KANSAS</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Douglas</PriceText>

                <PriceText> Hutchinson</PriceText>

                <PriceText> Independence</PriceText>

                <PriceText> Lawrence</PriceText>

                <PriceText> Olathe</PriceText>

                <PriceText> Overland Park</PriceText>

                <PriceText> Partridge</PriceText>

                <PriceText> Phillipsburg</PriceText>

                <PriceText> Russell</PriceText>

                <PriceText> Salina</PriceText>

                <PriceText> South Hutchinson</PriceText>

                <PriceText> Topeka</PriceText>

                <PriceText> Wichita </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>KENTUCKY</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Bowling Green</PriceText>

                <PriceText> Carrollton</PriceText>

                <PriceText> Columbia</PriceText>

                <PriceText> Corbin</PriceText>

                <PriceText> Elizabethtown</PriceText>

                <PriceText> Falmouth</PriceText>

                <PriceText> Florence</PriceText>

                <PriceText> Frankfort</PriceText>

                <PriceText> Glasgow</PriceText>

                <PriceText> Henderson</PriceText>

                <PriceText> Hopkinsville</PriceText>

                <PriceText> Lexington</PriceText>

                <PriceText> London</PriceText>

                <PriceText> Louisville</PriceText>

                <PriceText> Ludlow</PriceText>

                <PriceText> McDowell</PriceText>

                <PriceText> Mt Sterling</PriceText>

                <PriceText> Murray</PriceText>

                <PriceText> Owensboro</PriceText>

                <PriceText> Radcliff</PriceText>

                <PriceText> Richmond</PriceText>

                <PriceText> Stanford</PriceText>

                <PriceText> Waynesburg</PriceText>

                <PriceText> West Liberty</PriceText>

                <PriceText> Williamsburg </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>LOUISIANA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Alexandria</PriceText>

                <PriceText> Baton Rouge</PriceText>

                <PriceText> Bossier City</PriceText>

                <PriceText> Covington</PriceText>

                <PriceText> Crowley</PriceText>

                <PriceText> DeRidder</PriceText>

                <PriceText> Hammond</PriceText>

                <PriceText> Harvey</PriceText>

                <PriceText> Houma</PriceText>

                <PriceText> Jena</PriceText>

                <PriceText> Kenner</PriceText>

                <PriceText> Kentwood</PriceText>

                <PriceText> Lafayette</PriceText>

                <PriceText> Lake Charles</PriceText>

                <PriceText> LaPlace</PriceText>

                <PriceText> Metairie</PriceText>

                <PriceText> Marrero</PriceText>

                <PriceText> Monroec</PriceText>

                <PriceText> New Orleans</PriceText>

                <PriceText> Opelousas</PriceText>

                <PriceText> Pineville</PriceText>

                <PriceText> Plaquemine</PriceText>

                <PriceText> Shreveport</PriceText>

                <PriceText> Slidell</PriceText>

                <PriceText> Sulphur</PriceText>

                <PriceText> Walker</PriceText>

                <PriceText> West Monroe </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>MAINE</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Augusta</PriceText>

                <PriceText> Bangor</PriceText>

                <PriceText> Caribou</PriceText>

                <PriceText> Glenburn</PriceText>

                <PriceText> Hiram</PriceText>

                <PriceText> Livermore Falls</PriceText>

                <PriceText> Madison</PriceText>

                <PriceText> Newport</PriceText>

                <PriceText> Portland</PriceText>

                <PriceText> South Portland</PriceText>

                <PriceText> Wells</PriceText>

                <PriceText> Windham </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>MARYLAND</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Aberdeen</PriceText>

                <PriceText> Annapolis</PriceText>

                <PriceText> Baltimore</PriceText>

                <PriceText> Bel Air</PriceText>

                <PriceText> Beltsville</PriceText>

                <PriceText> Bowie</PriceText>

                <PriceText> Brentwood</PriceText>

                <PriceText> California</PriceText>

                <PriceText> Camp Springs</PriceText>

                <PriceText> Capitol Heights</PriceText>

                <PriceText> Catonsville</PriceText>

                <PriceText> Clinton</PriceText>

                <PriceText> Columbia</PriceText>

                <PriceText> Cumberland</PriceText>

                <PriceText> Damascus</PriceText>

                <PriceText> Denton</PriceText>

                <PriceText> District Heights</PriceText>

                <PriceText> Edgewood</PriceText>

                <PriceText> Ellicott City</PriceText>

                <PriceText> Essex</PriceText>

                <PriceText> Forestville</PriceText>

                <PriceText> Fort Washington</PriceText>

                <PriceText> Frederick</PriceText>

                <PriceText> Frostburg</PriceText>

                <PriceText> Gaithersburg</PriceText>

                <PriceText> Glen Burnie</PriceText>

                <PriceText> Greenbelt</PriceText>

                <PriceText> Greensboro</PriceText>

                <PriceText> Hagerstown</PriceText>

                <PriceText> Halethorpe</PriceText>

                <PriceText> Hyattsville</PriceText>

                <PriceText> Jessup</PriceText>

                <PriceText> Laurel</PriceText>

                <PriceText> Maryland City</PriceText>

                <PriceText> Montgomery</PriceText>

                <PriceText> Morningside</PriceText>

                <PriceText> Oakland</PriceText>

                <PriceText> Ocean City</PriceText>

                <PriceText> Oxon Hill</PriceText>

                <PriceText> Parkville</PriceText>

                <PriceText> Prince Frederick</PriceText>

                <PriceText> Reisterstown</PriceText>

                <PriceText> Riverdale</PriceText>

                <PriceText> Salisbury</PriceText>

                <PriceText> Silver Spring</PriceText>

                <PriceText> Suitland</PriceText>

                <PriceText> Temple Hills</PriceText>

                <PriceText> Towson</PriceText>

                <PriceText> Upper Marlboro</PriceText>

                <PriceText> Waldorf</PriceText>

                <PriceText> Westminster </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>MASSACHUSETTS</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Boston</PriceText>

                <PriceText> Brockton</PriceText>

                <PriceText> Leominster</PriceText>

                <PriceText> Malden</PriceText>

                <PriceText> Marblehead</PriceText>

                <PriceText> Medford</PriceText>

                <PriceText> Newton</PriceText>

                <PriceText> Northborough</PriceText>

                <PriceText> Palmer</PriceText>

                <PriceText> Plympton</PriceText>

                <PriceText> Revere</PriceText>

                <PriceText> Southbridge</PriceText>

                <PriceText> Springfield</PriceText>

                <PriceText> Walpole</PriceText>

                <PriceText> Waltham</PriceText>

                <PriceText> Worcester</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>MICHIGAN</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Ann Arbor</PriceText>

                <PriceText> Bay City</PriceText>

                <PriceText> Clare</PriceText>

                <PriceText> Clinton Township</PriceText>

                <PriceText> Clio</PriceText>

                <PriceText> Dearborn</PriceText>

                <PriceText> Detroit</PriceText>

                <PriceText> Dundee</PriceText>

                <PriceText> Durand</PriceText>

                <PriceText> Eastpointe</PriceText>

                <PriceText> Egan</PriceText>

                <PriceText> Flint</PriceText>

                <PriceText> Fowlerville</PriceText>

                <PriceText> Grand Blanc</PriceText>

                <PriceText> Grand Rapids</PriceText>

                <PriceText> Grosse Pointe Park</PriceText>

                <PriceText> Holland</PriceText>

                <PriceText> Jackson</PriceText>

                <PriceText> Kalamazoo</PriceText>

                <PriceText> Lansing</PriceText>

                <PriceText> Livonia</PriceText>

                <PriceText> Lowell</PriceText>

                <PriceText> Macomb</PriceText>

                <PriceText> Mount Pleasant</PriceText>

                <PriceText> Novi</PriceText>

                <PriceText> Pontiac</PriceText>

                <PriceText> Rochester Hills</PriceText>

                <PriceText> Rochester</PriceText>

                <PriceText> Saginaw</PriceText>

                <PriceText> Shelby Charter Township</PriceText>

                <PriceText> South Lyon</PriceText>

                <PriceText> Southfield</PriceText>

                <PriceText> St Charles</PriceText>

                <PriceText> St Johns</PriceText>

                <PriceText> Sterling Heights</PriceText>

                <PriceText> Traverse City</PriceText>

                <PriceText> Warren</PriceText>

                <PriceText> Waterford Township </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>MINNESOTA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Akeley </PriceText>

                <PriceText> Blaine </PriceText>

                <PriceText> Buffalo </PriceText>

                <PriceText> Burnsville </PriceText>

                <PriceText> Circle Pines </PriceText>

                <PriceText> Coon Rapids </PriceText>

                <PriceText> Cottage Grove </PriceText>

                <PriceText> Crookston </PriceText>

                <PriceText> Eagan </PriceText>

                <PriceText> Hibbing </PriceText>

                <PriceText> Hopkins </PriceText>

                <PriceText> Inver Grove Heights </PriceText>

                <PriceText> Ironton </PriceText>

                <PriceText> Kasson </PriceText>

                <PriceText> Lakeville </PriceText>

                <PriceText> Mankato </PriceText>

                <PriceText> Minneapolis </PriceText>

                <PriceText> Minnesota City </PriceText>

                <PriceText> Minnetonka </PriceText>

                <PriceText> Montrose </PriceText>

                <PriceText> Moorhead </PriceText>

                <PriceText> Mounds View </PriceText>

                <PriceText> Plymouth </PriceText>

                <PriceText> Saint Paul </PriceText>

                <PriceText> South Saint Paul </PriceText>

                <PriceText> Savage </PriceText>

                <PriceText> Thief River Falls </PriceText>

                <PriceText> Twin Valley </PriceText>

                <PriceText> Vadnais Heights </PriceText>

                <PriceText> White Bear Lake </PriceText>

                <PriceText> Woodbury </PriceText>

                <PriceText> Zimmerman </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>MISSISSIPPI</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Amory</PriceText>

                <PriceText> Booneville</PriceText>

                <PriceText> Carthage</PriceText>

                <PriceText> Columbia</PriceText>

                <PriceText> Flora</PriceText>

                <PriceText> Flowood</PriceText>

                <PriceText> Greenville</PriceText>

                <PriceText> Greenwood</PriceText>

                <PriceText> Gulfport</PriceText>

                <PriceText> Jackson</PriceText>

                <PriceText> Laurel</PriceText>

                <PriceText> Meridian</PriceText>

                <PriceText> Philadelphia</PriceText>

                <PriceText> Polarville</PriceText>

                <PriceText> Southaven</PriceText>

                <PriceText> Tupelo</PriceText>

                <PriceText> Vicksburg</PriceText>

                <PriceText> Waveland</PriceText>

                <PriceText> Weir</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>MISSOURI</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Arnold</PriceText>

                <PriceText> Cape Girardeau</PriceText>

                <PriceText> Cedar Hill</PriceText>

                <PriceText> Crystal City</PriceText>

                <PriceText> Farmington</PriceText>

                <PriceText> Fulton</PriceText>

                <PriceText> Hannibal</PriceText>

                <PriceText> Harrisonville</PriceText>

                <PriceText> Independence</PriceText>

                <PriceText> Kansas City</PriceText>

                <PriceText> Lebanon</PriceText>

                <PriceText> Marshfield</PriceText>

                <PriceText> Osage Beach</PriceText>

                <PriceText> Perryville</PriceText>

                <PriceText> Saint Louis</PriceText>

                <PriceText> Saint Robert</PriceText>

                <PriceText> Springfield</PriceText>

                <PriceText> St Charles</PriceText>

                <PriceText> St louis</PriceText>

                <PriceText> Stover</PriceText>

                <PriceText> Troy</PriceText>

                <PriceText> Wentzville </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>MONTANA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Billings</PriceText>

                <PriceText> Bozeman</PriceText>

                <PriceText> Helena</PriceText>

                <PriceText> Libby </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>NEBRASKA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Lincoln</PriceText>

                <PriceText>Omaha</PriceText>

                <PriceText> Snyder </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>NEVADA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Henderson</PriceText>

                <PriceText>Las Vegas</PriceText>

                <PriceText>Reno </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>NEW HAMPSHIRE</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Candia</PriceText>

                <PriceText> Goffstown</PriceText>

                <PriceText> Manchester</PriceText>

                <PriceText> Milford</PriceText>

                <PriceText>Nashua</PriceText>

                <PriceText>Seabrook </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>NEW JERSEY</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText> Asbury Park</PriceText>

                <PriceText> Atlantic City</PriceText>

                <PriceText> Atlantic County</PriceText>

                <PriceText> Barrington</PriceText>

                <PriceText> Bayonne</PriceText>

                <PriceText> Bergenfield</PriceText>

                <PriceText> Berkeley Township</PriceText>

                <PriceText> Bloomfield</PriceText>

                <PriceText> Bridgewater</PriceText>

                <PriceText> Burlington</PriceText>

                <PriceText> Camden</PriceText>

                <PriceText> Carteret</PriceText>

                <PriceText> Cherry Hill</PriceText>

                <PriceText> Clifton</PriceText>

                <PriceText> Deptford Township</PriceText>

                <PriceText> East Brunswick</PriceText>

                <PriceText> East Orange</PriceText>

                <PriceText> Egg Harbor Township</PriceText>

                <PriceText> Elizabeth</PriceText>

                <PriceText> Elmwood Park</PriceText>

                <PriceText> Englewood</PriceText>

                <PriceText> Evesham</PriceText>

                <PriceText> Fairfield</PriceText>

                <PriceText> Forked River</PriceText>

                <PriceText> Fort Lee</PriceText>

                <PriceText> Franklin Township</PriceText>

                <PriceText> Glassboro</PriceText>

                <PriceText> Hackensack</PriceText>

                <PriceText> Haddon</PriceText>

                <PriceText> Hamilton</PriceText>

                <PriceText> Hammonton</PriceText>

                <PriceText> Highland Park</PriceText>

                <PriceText> Howell</PriceText>

                <PriceText> Jackson</PriceText>

                <PriceText> Jersey City</PriceText>

                <PriceText> Kearny</PriceText>

                <PriceText> Lakewood</PriceText>

                <PriceText> Lawrence</PriceText>

                <PriceText> Linden</PriceText>

                <PriceText> Lodi</PriceText>

                <PriceText> Mahwah</PriceText>

                <PriceText> Medford</PriceText>

                <PriceText> Middlesex</PriceText>

                <PriceText> Millville</PriceText>

                <PriceText> Montclair</PriceText>

                <PriceText> Mount Holly</PriceText>

                <PriceText> Neptune City</PriceText>

                <PriceText> Newark</PriceText>

                <PriceText> Ocean County</PriceText>

                <PriceText> Ocean Township</PriceText>

                <PriceText> Old bridge</PriceText>

                <PriceText> Paramus</PriceText>

                <PriceText> Paterson</PriceText>

                <PriceText> Paulsboro</PriceText>

                <PriceText> Perth Amboy</PriceText>

                <PriceText> Plainfield</PriceText>

                <PriceText> Princeton</PriceText>

                <PriceText> Roxbury</PriceText>

                <PriceText> Stratford</PriceText>

                <PriceText> Toms River</PriceText>

                <PriceText> Trenton</PriceText>

                <PriceText> Washington</PriceText>

                <PriceText> Wayne</PriceText>

                <PriceText> Woodland Park </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>NEW MEXICO</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Albuquerque</PriceText>

                <PriceText> Chaparral</PriceText>

                <PriceText> Hobbs</PriceText>

                <PriceText> Lovington</PriceText>

                <PriceText> Santa Fe</PriceText>

                <PriceText> Silver City</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>NEW YORK</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Albany</PriceText>

                <PriceText> Amityville</PriceText>

                <PriceText> Baldwin</PriceText>

                <PriceText> Bay Shore</PriceText>

                <PriceText> Bellport</PriceText>

                <PriceText> Boonville</PriceText>

                <PriceText> Brewster</PriceText>

                <PriceText> Bronxville</PriceText>

                <PriceText> Buffalo</PriceText>

                <PriceText> Carmel</PriceText>

                <PriceText> Copiague</PriceText>

                <PriceText> Chester</PriceText>

                <PriceText> Delhi</PriceText>

                <PriceText> Elmont</PriceText>

                <PriceText> Fulton</PriceText>

                <PriceText> Hamburg</PriceText>

                <PriceText> Holtsville</PriceText>

                <PriceText> Huntington</PriceText>

                <PriceText> Islip</PriceText>

                <PriceText> Ithaca</PriceText>

                <PriceText> Lafayette</PriceText>

                <PriceText> Lindenhurst</PriceText>

                <PriceText> Mahopac</PriceText>

                <PriceText> Marlboro</PriceText>

                <PriceText> Medford</PriceText>

                <PriceText> Middletown</PriceText>

                <PriceText> Monroe</PriceText>

                <PriceText> Mount Vernon</PriceText>

                <PriceText> Mt Upton</PriceText>

                <PriceText> New York City</PriceText>

                <PriceText> Oneida</PriceText>

                <PriceText> Owego</PriceText>

                <PriceText> Patchogue</PriceText>

                <PriceText> Peekskill</PriceText>

                <PriceText> Pelham Manor</PriceText>

                <PriceText> Phelps</PriceText>

                <PriceText> Pleasantville</PriceText>

                <PriceText> Port Chester</PriceText>

                <PriceText> Poughkeepsie</PriceText>

                <PriceText> Putnam Valley</PriceText>

                <PriceText> Queens</PriceText>

                <PriceText> Queensbury</PriceText>

                <PriceText> Rochester</PriceText>

                <PriceText> Rye Brook</PriceText>

                <PriceText> Saratoga Springs</PriceText>

                <PriceText> Saugerties</PriceText>

                <PriceText> Schenectady</PriceText>

                <PriceText> Skaneateles</PriceText>

                <PriceText> Syracuse</PriceText>

                <PriceText> Utica</PriceText>

                <PriceText> Wallkill</PriceText>

                <PriceText> Whitesboro</PriceText>

                <PriceText> Woodside</PriceText>

                <PriceText> Yonkers</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>NORTH CAROLINA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Asheville</PriceText>

                <PriceText> Aulander</PriceText>

                <PriceText> Banner Elk</PriceText>

                <PriceText> Boone</PriceText>

                <PriceText> Boonville</PriceText>

                <PriceText> Brevard</PriceText>

                <PriceText> Burlington</PriceText>

                <PriceText> Butner</PriceText>

                <PriceText> Carrboro</PriceText>

                <PriceText> Carthage</PriceText>

                <PriceText> Chapel Hill</PriceText>

                <PriceText> Charlotte</PriceText>

                <PriceText> Currituck</PriceText>

                <PriceText> Durham</PriceText>

                <PriceText> Eden</PriceText>

                <PriceText> Elizabeth City</PriceText>

                <PriceText> Elkin</PriceText>

                <PriceText> Emerald Isle</PriceText>

                <PriceText> Fayetteville</PriceText>

                <PriceText> Garysburg</PriceText>

                <PriceText> Garysville</PriceText>

                <PriceText> Gastonia</PriceText>

                <PriceText> Goldsboro</PriceText>

                <PriceText> Greensboro</PriceText>

                <PriceText> Greenville</PriceText>

                <PriceText> Henderson</PriceText>

                <PriceText> Hickory</PriceText>

                <PriceText> Hillsborough</PriceText>

                <PriceText> Kannapolis</PriceText>

                <PriceText> Knightdale</PriceText>

                <PriceText> Lancaster</PriceText>

                <PriceText> Leland</PriceText>

                <PriceText> Lexington</PriceText>

                <PriceText> Lowell</PriceText>

                <PriceText> Lumberton</PriceText>

                <PriceText> Marion</PriceText>

                <PriceText> Mocksville</PriceText>

                <PriceText> Monroe</PriceText>

                <PriceText> Mooresville</PriceText>

                <PriceText> Raleigh</PriceText>

                <PriceText> North Wilkesboro</PriceText>

                <PriceText> Roanoke Rapids</PriceText>

                <PriceText> Rocky Mount</PriceText>

                <PriceText> Rowland</PriceText>

                <PriceText> Roxboro</PriceText>

                <PriceText> Salemburg</PriceText>

                <PriceText> Lillington</PriceText>

                <PriceText> Sanford</PriceText>

                <PriceText> Smithsfield</PriceText>

                <PriceText> Spencer</PriceText>

                <PriceText> Saint Pauls</PriceText>

                <PriceText> Shelby</PriceText>

                <PriceText> Stoneville</PriceText>

                <PriceText> Tabor City</PriceText>

                <PriceText> Tarboro</PriceText>

                <PriceText> Wake Forest</PriceText>

                <PriceText> Washington</PriceText>

                <PriceText> Whiteville</PriceText>

                <PriceText> Wilson</PriceText>

                <PriceText> Windsor</PriceText>

                <PriceText> Winston</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>NORTH DAKOTA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Bismarck</PriceText>

                <PriceText>Fargo</PriceText>

                <PriceText> Grand Forks</PriceText>

                <PriceText> Minot</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>OHIO</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Akron</PriceText>

                <PriceText>Alliance</PriceText>

                <PriceText>Athens</PriceText>

                <PriceText>Avon</PriceText>

                <PriceText>Bedford</PriceText>

                <PriceText>Berea</PriceText>

                <PriceText>Bidwell</PriceText>

                <PriceText>Bradford</PriceText>

                <PriceText>Canton</PriceText>

                <PriceText>Chardon</PriceText>

                <PriceText>Cincinnati</PriceText>

                <PriceText>Cleveland</PriceText>

                <PriceText>Columbus</PriceText>

                <PriceText>Crooksville</PriceText>

                <PriceText>Dayton</PriceText>

                <PriceText>Elyria</PriceText>

                <PriceText>Fairfield</PriceText>

                <PriceText>Fostoria</PriceText>

                <PriceText>Fowler</PriceText>

                <PriceText>Gallipolis</PriceText>

                <PriceText>Garfield Heights</PriceText>

                <PriceText>Hamilton</PriceText>

                <PriceText>Lebanon</PriceText>

                <PriceText>Loraine</PriceText>

                <PriceText>Madison</PriceText>

                <PriceText>Mansfield</PriceText>

                <PriceText>Martins Ferry</PriceText>

                <PriceText>Massillon</PriceText>

                <PriceText>Mayfield</PriceText>

                <PriceText>McClure</PriceText>

                <PriceText>Mount Vernon</PriceText>

                <PriceText>Newark</PriceText>

                <PriceText>Painesville</PriceText>

                <PriceText>Plain City</PriceText>

                <PriceText>Salem</PriceText>

                <PriceText>Sandusky</PriceText>

                <PriceText>Springfield</PriceText>

                <PriceText>Steubenville</PriceText>

                <PriceText>Summit</PriceText>

                <PriceText>Toledo</PriceText>

                <PriceText>Van Wert</PriceText>

                <PriceText>Vandalia</PriceText>

                <PriceText>Wapakoneta</PriceText>

                <PriceText>Warrensville Heights</PriceText>

                <PriceText>Waverly</PriceText>

                <PriceText>Wellsville</PriceText>

                <PriceText>Wickliffe</PriceText>

                <PriceText>Youngstown</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>OKLAHOMA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Ada</PriceText>

                <PriceText> Ardmore</PriceText>

                <PriceText> Blanchard</PriceText>

                <PriceText> Dover</PriceText>

                <PriceText> El Reno</PriceText>

                <PriceText> Grove</PriceText>

                <PriceText> Muskogee</PriceText>

                <PriceText> Oklahoma City</PriceText>

                <PriceText> Pauls Valley</PriceText>

                <PriceText> Purcell</PriceText>

                <PriceText> Sayre</PriceText>

                <PriceText> Shawnee</PriceText>

                <PriceText> Stigler</PriceText>

                <PriceText> Stillwater</PriceText>

                <PriceText> Tulsa </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>OREGON</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Albany</PriceText>

                <PriceText> Bend</PriceText>

                <PriceText> Central Point</PriceText>

                <PriceText> Clackamas</PriceText>

                <PriceText> Grants Pass</PriceText>

                <PriceText> Klamath Falls</PriceText>

                <PriceText> La Pine</PriceText>

                <PriceText> Lebanon</PriceText>

                <PriceText> McMinnville</PriceText>

                <PriceText> Portland</PriceText>

                <PriceText> Roseburg</PriceText>

                <PriceText> Salem</PriceText>

                <PriceText> Seaside</PriceText>

                <PriceText> Sherwood</PriceText>

                <PriceText> Sweet Home</PriceText>

                <PriceText> Tigard</PriceText>

                <PriceText> Tillamook </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>PENNSYLVANIA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Allentown</PriceText>

                <PriceText> Aliquippa</PriceText>

                <PriceText> Ambridge</PriceText>

                <PriceText> Annville</PriceText>

                <PriceText> Athens</PriceText>

                <PriceText> Beaver</PriceText>

                <PriceText> Beaver Falls</PriceText>

                <PriceText> Bensalem</PriceText>

                <PriceText> Bethlehem</PriceText>

                <PriceText> Birdsboro</PriceText>

                <PriceText> Bloomsburg</PriceText>

                <PriceText> Braddock</PriceText>

                <PriceText> Bristol</PriceText>

                <PriceText> Brookhaven</PriceText>

                <PriceText> Broomall</PriceText>

                <PriceText> Carlisle</PriceText>

                <PriceText> Chambersburg</PriceText>

                <PriceText> Chester</PriceText>

                <PriceText> Connellsville</PriceText>

                <PriceText> Dallas</PriceText>

                <PriceText> Danville</PriceText>

                <PriceText> Darby</PriceText>

                <PriceText> Downington</PriceText>

                <PriceText> Drexel Hill</PriceText>

                <PriceText> East Norrington</PriceText>

                <PriceText> East Springfield</PriceText>

                <PriceText> Elkins Park</PriceText>

                <PriceText> Emmaus</PriceText>

                <PriceText> Fairless Hills</PriceText>

                <PriceText> Folcroft</PriceText>

                <PriceText> Frackville</PriceText>

                <PriceText> Glenside</PriceText>

                <PriceText> Greensboro</PriceText>

                <PriceText> Greensburg</PriceText>

                <PriceText> Harrisburg</PriceText>

                <PriceText> Hazleton</PriceText>

                <PriceText> Huntingdon</PriceText>

                <PriceText> Irwin</PriceText>

                <PriceText> Johnstown</PriceText>

                <PriceText> Jonestown</PriceText>

                <PriceText> King of Prussia</PriceText>

                <PriceText> Kittanning</PriceText>

                <PriceText> Lancaster</PriceText>

                <PriceText> Langhorne</PriceText>

                <PriceText> Lansdale</PriceText>

                <PriceText> Lansdowne</PriceText>

                <PriceText> Latrobe</PriceText>

                <PriceText> Lemoyne</PriceText>

                <PriceText> Levittown</PriceText>

                <PriceText> Marcus Hook</PriceText>

                <PriceText> Montrose</PriceText>

                <PriceText> Malvern</PriceText>

                <PriceText> Mechanicsburg</PriceText>

                <PriceText> Morgantown</PriceText>

                <PriceText> Morrisville</PriceText>

                <PriceText> Mt Carmel</PriceText>

                <PriceText> New Galilee</PriceText>

                <PriceText> New Ringgold</PriceText>

                <PriceText> Newtown</PriceText>

                <PriceText> Norristown</PriceText>

                <PriceText> Oley</PriceText>

                <PriceText> Orangeville</PriceText>

                <PriceText> Orwigsburg</PriceText>

                <PriceText> Oxford</PriceText>

                <PriceText> Parkside</PriceText>

                <PriceText> Perkiomenville</PriceText>

                <PriceText> Philadelphia</PriceText>

                <PriceText> Pittsburgh</PriceText>

                <PriceText> Reading</PriceText>

                <PriceText> Scranton</PriceText>

                <PriceText> Shamokin</PriceText>

                <PriceText> Shenandoah</PriceText>

                <PriceText> Smithton</PriceText>

                <PriceText> Southampton</PriceText>

                <PriceText> Spring Grove</PriceText>

                <PriceText> Springboro</PriceText>

                <PriceText> State College</PriceText>

                <PriceText> Tamaqua</PriceText>

                <PriceText> Tower City</PriceText>

                <PriceText> Tremont</PriceText>

                <PriceText> Tullytown</PriceText>

                <PriceText> Upper Darby</PriceText>

                <PriceText> Waynesburg</PriceText>

                <PriceText> West Chester</PriceText>

                <PriceText> Westfield</PriceText>

                <PriceText> York </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>RHODE ISLAND</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>North Kingstown</PriceText>

                <PriceText> Providence</PriceText>

                <PriceText> Warwick</PriceText>

                <PriceText> West Warwick</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>SOUTH CAROLINA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText> Beaufort</PriceText>

                <PriceText> Bennettsville</PriceText>

                <PriceText> Bluffton</PriceText>

                <PriceText> Central</PriceText>

                <PriceText> Charleston</PriceText>

                <PriceText> Chester</PriceText>

                <PriceText> Columbia</PriceText>

                <PriceText> Cottageville</PriceText>

                <PriceText> Easley</PriceText>

                <PriceText> Elgin</PriceText>

                <PriceText> Florence</PriceText>

                <PriceText> Gaffney</PriceText>

                <PriceText> Greenville</PriceText>

                <PriceText> Hemingway</PriceText>

                <PriceText> Irmo</PriceText>

                <PriceText> Lae City</PriceText>

                <PriceText> Lancaster</PriceText>

                <PriceText> Lexington</PriceText>

                <PriceText> Little River</PriceText>

                <PriceText> Loris</PriceText>

                <PriceText> Lugoff</PriceText>

                <PriceText> Marion</PriceText>

                <PriceText> Moncks Corner</PriceText>

                <PriceText> Myrtle Beach</PriceText>

                <PriceText> North Augusta</PriceText>

                <PriceText> North Charleston</PriceText>

                <PriceText> Orangeburg</PriceText>

                <PriceText> Pickens</PriceText>

                <PriceText> Powdersville</PriceText>

                <PriceText> Rock Hill</PriceText>

                <PriceText> Santee</PriceText>

                <PriceText> Simpsonville</PriceText>

                <PriceText> Spartanburg</PriceText>

                <PriceText> St George</PriceText>

                <PriceText> St Matthews</PriceText>

                <PriceText> St Stephen</PriceText>

                <PriceText> Summerton</PriceText>

                <PriceText> Summerville</PriceText>

                <PriceText> Sumter</PriceText>

                <PriceText> Union</PriceText>

                <PriceText> Wagener</PriceText>

                <PriceText> West Columbia</PriceText>

                <PriceText> Williston </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>SOUTH DAKOTA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Flandreau</PriceText>

                <PriceText>Rapid City</PriceText>

                <PriceText>Sioux Falls</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>TENNESSEE</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Ashland City</PriceText>

                <PriceText> Bluff City</PriceText>

                <PriceText> Bristol</PriceText>

                <PriceText> Chattanooga</PriceText>

                <PriceText> Clarksville</PriceText>

                <PriceText> Cleveland</PriceText>

                <PriceText> Columbia</PriceText>

                <PriceText> Dayton</PriceText>

                <PriceText> Dunlap</PriceText>

                <PriceText> Dyersburg</PriceText>

                <PriceText> Franklin</PriceText>

                <PriceText> Goodlettsville</PriceText>

                <PriceText> Hendersonville</PriceText>

                <PriceText> Hermitage</PriceText>

                <PriceText> Johnson City</PriceText>

                <PriceText> Knoxville</PriceText>

                <PriceText> McMinnville</PriceText>

                <PriceText> Memphis</PriceText>

                <PriceText> Mount Juliet</PriceText>

                <PriceText> Murfreesboro</PriceText>

                <PriceText> Nashville</PriceText>

                <PriceText> Oak Ridge</PriceText>

                <PriceText> Pleasant View</PriceText>

                <PriceText> Sevierville</PriceText>

                <PriceText> Shelbyville</PriceText>

                <PriceText> Smyrna</PriceText>

                <PriceText> Spencer</PriceText>

                <PriceText> Tazewell</PriceText>

                <PriceText> Tullahoma</PriceText>

                <PriceText> White House</PriceText>

                <PriceText> Whiteville </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>TEXAS</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Abilene</PriceText>

                <PriceText> Alice</PriceText>

                <PriceText> Allen</PriceText>

                <PriceText> Arlington</PriceText>

                <PriceText> Athens</PriceText>

                <PriceText> Austin</PriceText>

                <PriceText> Balch Springs</PriceText>

                <PriceText> Baytown</PriceText>

                <PriceText> Beaumont</PriceText>

                <PriceText> Bedford</PriceText>

                <PriceText> Belton</PriceText>

                <PriceText> Bertram</PriceText>

                <PriceText> Bourne</PriceText>

                <PriceText> Boyd</PriceText>

                <PriceText> Brenham</PriceText>

                <PriceText> Brownsville</PriceText>

                <PriceText> Bryan</PriceText>

                <PriceText> Burleson</PriceText>

                <PriceText> Burnet</PriceText>

                <PriceText> Converse</PriceText>

                <PriceText> Corpus Christi</PriceText>

                <PriceText> Dallas</PriceText>

                <PriceText> Edinburg</PriceText>

                <PriceText> El Paso</PriceText>

                <PriceText> Forney</PriceText>

                <PriceText> Fort Worth</PriceText>

                <PriceText> Harlingen</PriceText>

                <PriceText> Haslet</PriceText>

                <PriceText> Houston</PriceText>

                <PriceText> Katy</PriceText>

                <PriceText> Killeen</PriceText>

                <PriceText> Laredo</PriceText>

                <PriceText> Liberty Hill</PriceText>

                <PriceText> Lubbock</PriceText>

                <PriceText> Marshall</PriceText>

                <PriceText> Melissa</PriceText>

                <PriceText> Mercedes</PriceText>

                <PriceText> New Braunfels</PriceText>

                <PriceText> North Richard Hills</PriceText>

                <PriceText> Pasadena</PriceText>

                <PriceText> Plano</PriceText>

                <PriceText> Princeton</PriceText>

                <PriceText> Richard Hills</PriceText>

                <PriceText> San Antonio</PriceText>

                <PriceText> Seagoville</PriceText>

                <PriceText> Sugarland</PriceText>

                <PriceText> Temple</PriceText>

                <PriceText> Waco</PriceText>

                <PriceText> Watauga</PriceText>

                <PriceText> Waxahachie</PriceText>

                <PriceText> Wichita Falls</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>UTAH</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Green River</PriceText>

                <PriceText> Layton</PriceText>

                <PriceText> Lindon</PriceText>

                <PriceText> Magna</PriceText>

                <PriceText> Manti</PriceText>

                <PriceText> Orem</PriceText>

                <PriceText> Payson</PriceText>

                <PriceText> Provo</PriceText>

                <PriceText> Salt Lake City</PriceText>

                <PriceText> South Salt Lake</PriceText>

                <PriceText> Spanish Fork</PriceText>

                <PriceText> Tremonton</PriceText>

                <PriceText> West Valley City </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>VERMONT</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Barre </PriceText>

                <PriceText>Brattleboro </PriceText>

                <PriceText> Stowe </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>VIRGINIA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Alexandria</PriceText>

                <PriceText> Arlington</PriceText>

                <PriceText> Ashburn</PriceText>

                <PriceText> Ashland</PriceText>

                <PriceText> Bristol</PriceText>

                <PriceText> Caroline</PriceText>

                <PriceText> Centreville</PriceText>

                <PriceText> Charlottesville</PriceText>

                <PriceText> Chesapeake</PriceText>

                <PriceText> Chesterfield</PriceText>

                <PriceText> Colonial Heights</PriceText>

                <PriceText> Edinburg</PriceText>

                <PriceText> Fairfax</PriceText>

                <PriceText> Fieldale</PriceText>

                <PriceText> Fredericksburg</PriceText>

                <PriceText> Front Royal</PriceText>

                <PriceText> Gainesville</PriceText>

                <PriceText> Goochland</PriceText>

                <PriceText> Gum Spring</PriceText>

                <PriceText> Glen Allen</PriceText>

                <PriceText> Hampton</PriceText>

                <PriceText> Harrisonburg</PriceText>

                <PriceText> Henrico</PriceText>

                <PriceText> Herndon</PriceText>

                <PriceText> Lynchburg</PriceText>

                <PriceText> Lexington</PriceText>

                <PriceText> Mechanicsville</PriceText>

                <PriceText> Newport News</PriceText>

                <PriceText> Norfolk</PriceText>

                <PriceText> Northern</PriceText>

                <PriceText> Orange</PriceText>

                <PriceText> Petersburg</PriceText>

                <PriceText> Portsmouth</PriceText>

                <PriceText> Reston</PriceText>

                <PriceText> Richmond</PriceText>

                <PriceText> Roanoke</PriceText>

                <PriceText> Shacklefords</PriceText>

                <PriceText> Smithfield</PriceText>

                <PriceText> Springfield</PriceText>

                <PriceText> Suffolk</PriceText>

                <PriceText> Surry</PriceText>

                <PriceText> Virginia Beach</PriceText>

                <PriceText> Waynesboro</PriceText>

                <PriceText> Williamsburg</PriceText>

                <PriceText> Winchester</PriceText>

                <PriceText> Woodbridge</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>WASHINGTON</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Auburn</PriceText>

                <PriceText> Bellevue</PriceText>

                <PriceText> Bothell</PriceText>

                <PriceText> Chelan</PriceText>

                <PriceText> Chehalis</PriceText>

                <PriceText> Chewelah</PriceText>

                <PriceText> Edmonds</PriceText>

                <PriceText> Everett</PriceText>

                <PriceText> Lakewood</PriceText>

                <PriceText> Lynnwood</PriceText>

                <PriceText> Marysville</PriceText>

                <PriceText> Moses Lake</PriceText>

                <PriceText> North Bend</PriceText>

                <PriceText> Olympia</PriceText>

                <PriceText> Orting</PriceText>

                <PriceText> Parkland</PriceText>

                <PriceText> Puyallup</PriceText>

                <PriceText> SeaTac</PriceText>

                <PriceText> Seattle</PriceText>

                <PriceText> Spokane Valley</PriceText>

                <PriceText> Tacoma</PriceText>

                <PriceText> Vancouver</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>WEST VIRGINIA</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Barboursville</PriceText>

                <PriceText> Beckley</PriceText>

                <PriceText> Canvas</PriceText>

                <PriceText> Charleston</PriceText>

                <PriceText> Eastern</PriceText>

                <PriceText> Fairmont</PriceText>

                <PriceText> Inwood</PriceText>

                <PriceText> Martinsburg</PriceText>

                <PriceText> Milton</PriceText>

                <PriceText> Rainelle</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>WISCONSIN</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Ashland</PriceText>

                <PriceText> Burlington</PriceText>

                <PriceText> Crandon</PriceText>

                <PriceText> Dodgeville</PriceText>

                <PriceText> Eau Claire</PriceText>

                <PriceText> Green Bay</PriceText>

                <PriceText> Janesville</PriceText>

                <PriceText> La Crosse</PriceText>

                <PriceText> Madison</PriceText>

                <PriceText> Milwaukee</PriceText>

                <PriceText> Mondovi</PriceText>

                <PriceText> Racine</PriceText>

                <PriceText> Sheboygan</PriceText>

                <PriceText> Sturgeon Bay</PriceText>

                <PriceText> Sussex</PriceText>

                <PriceText> Wakesha</PriceText>

                <PriceText> Wausaukee</PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>

          <ThirdBox>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <HowText>WYOMING</HowText>
              </AccordionSummary>
              <AccordionDetails>
                <PriceText>Cheyenne </PriceText>
              </AccordionDetails>
            </Accordion>
          </ThirdBox>
        </Box>

        <Box>
          <Box>
            <WnatText>Want Truckifix Services?</WnatText>
            <SenodWantText>
              Want Truckifix in your city? Submit a request! We are recruiting
              service providers across the U.S. and your city could be next!
            </SenodWantText>
            <ButtomBox>
              <Button>REQUEST A CITY</Button>
            </ButtomBox>
            <hr></hr>
            <Box>
              <CustomerText>CUSTOMER ACCESS</CustomerText>
            </Box>
            <KartBox>
              <TypeTextBox>(Customer) Download Now </TypeTextBox>
              <AndriodIcon>
                <AdbIcon style={{ fontSize: "40px" }} />
              </AndriodIcon>
            </KartBox>

            <KartBox>
              <TypeTextBox>(Customer) Download Now </TypeTextBox>
              <AndriodIcon>
                <AppleIcon style={{ fontSize: "40px" }} />
              </AndriodIcon>
            </KartBox>
            <hr></hr>

            <Box>
              <CustomerText>PROVIDER ACCESS</CustomerText>
            </Box>
            <KartBox>
              <TypeTextBox>(Provider) Download Now</TypeTextBox>
              <AndriodIcon>
                <AdbIcon style={{ fontSize: "40px" }} />
              </AndriodIcon>
            </KartBox>

            <KartBox>
              <TypeTextBox>(Provider) Download Now</TypeTextBox>
              <AndriodIcon>
                <AppleIcon style={{ fontSize: "40px" }} />
              </AndriodIcon>
            </KartBox>

            <BackGif>
              <a href="tel:+18772210727">
                <DooText>Don't see your location? We can still help!</DooText>
                <LoaclIcon>
                  <LocalPhoneIcon style={{ fontSize: "40px" }} />
                </LoaclIcon>
              </a>
            </BackGif>
          </Box>
        </Box>
      </OmaxBox>
    </>
  );
};

export default Location;
