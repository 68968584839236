import React from 'react'
import House from '../Header/House'

import { Box, styled, Typography } from '@mui/material'
import AppleIcon from '@mui/icons-material/Apple';
import AdbIcon from '@mui/icons-material/Adb';
import FlatSecond from './FlatSecond';



const MainBox = styled(Box)(({ theme }) => ({
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
  backgroundRepeat: 'no-repeat, repeat',
  height: '600px',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  color: '#fff',
  overflow: 'hidden',
  backgroundAttachment: 'fixed',
  [theme.breakpoints.down('lg')]: {
    marginTop: '-10px',
    display: 'block',
    height: '860px',
    overflow: 'hidden',
  }
}))

const TextBox = styled(Box)(({ theme }) => ({
  fontSize: '45px',
  wordWrap: 'break-word',
  marginTop: '100px',
  marginLeft: '170px',
  letterSpacing: '2px',
  fontWeight: '600',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    fontSize: '20px',
    wordWrap: 'break-word',
    padding: '25px',
    marginTop: '10px',
    marginLeft: '25px',
    letterSpacing: '2px',
    fontWeight: '400',
    overflow: 'hidden',
  }
}))


const TextBoxGet = styled(Box)(({ theme }) => ({
  fontSize: '15px',
  marginTop: '10px',
  wordWrap: 'break-word',
  marginLeft: '170px',
  letterSpacing: '2px',
  fontWeight: '600',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    fontSize: '10px',
    marginTop: '-20px',
    marginLeft: '50px',
    letterSpacing: '1px',
    width: '85%',
    overflow: 'hidden',
  }
}))







const ImageNew = styled('img')(({ theme }) => ({
  width: '230px',
  height: '460px',
  marginRight: '300px',
  marginTop: '75px',

  [theme.breakpoints.down('lg')]: {
    width: '200px',
    height: '400px',
    marginLeft: '95px',
    marginTop: '30px',

  }
}))


const KartBox = styled(Box)(({ theme }) => ({
  width: '240px',
  height: '70px',
  background: 'rgb(41, 57, 141)',
  marginLeft: '170px',
  marginTop: '15px',
  borderRadius: '8px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '77px',
    marginTop: '20px',
    overflow: 'hidden',
  }

}))


const TypeTextBox = styled(Box)(({theme})=> ({
  marginLeft: '17px',
  width: '100%',
  padding: '22px',
  fontSize: '16px',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]:{
    overflow: 'hidden',
  }

}))
 

const AndriodIcon = styled(Box)`
  margin-left: 170px;
  margin-top: -56px;
`;


const TairMainBox = styled(Box)(({theme})=> ({
  display: 'flex',
  marginLeft: '310px',
  marginTop: '100px',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
    marginLeft: '31px',
    marginTop: '30px',
    overflow: 'hidden',
  }
}))



const ImageFont = styled('img')(({theme})=>({
    width: '570px',
    height: '250px',
  
    [theme.breakpoints.down('lg')]: {
      width: '290px',
      height: '200px',
      marginLeft:'18px',
      
    }
}))



const TexFild = styled(Typography)(({theme})=> ({
    marginTop: '10px',
    width: '50%',
    marginLeft: '30px',
    color: 'grey',
    fontSize: '20px',
    lineHeight: '28px',
    fontFamily: "'Open Sans', sans-serif",
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
      marginLeft: '9px',
      fontSize: '15px',
      overflow: 'hidden',
    }
}))



const JumbText = styled(Typography)(({theme})=> ({
    marginTop: '20px',
    fontSize: '22px',
    marginLeft: '30px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '12px',
    }
}))
  




const Flat = () => {
  return (
    <>
      <House />

      <MainBox>
        <Box>
          <TextBox>Have a punctured tire? We can help.</TextBox>
          <TextBoxGet>Getting a punctured tire can so disappoint. We're  assist you with getting back out and about as fast as could really be expected. We make getting emergency aides simpler than any time in recent memory with our on-request and mechanized process. Essentially select the help you want and our framework will consequently associate you with the specialist co-op that is nearest to you.</TextBoxGet>

          <KartBox>
            <TypeTextBox>Download Now </TypeTextBox>
            <AndriodIcon>
              <AppleIcon style={{ fontSize: '40px' }} />
            </AndriodIcon>
          </KartBox>

          <KartBox>
            <TypeTextBox>Download Now </TypeTextBox>
            <AndriodIcon>
              <AdbIcon style={{ fontSize: '40px' }} />
            </AndriodIcon>
          </KartBox>
        </Box>
        <ImageNew src="Image/89354.png" alt="" />
      </MainBox>

      <Box>
        <TairMainBox>
          <ImageFont src="https://img.ccjdigital.com/files/base/randallreilly/all/image/2022/06/TranService_air_pressure.62be06a45e863.png?auto=format%2Ccompress&fit=max&q=70&w=1200" alt="" />
          <Box>
            <JumbText>Change Flat Tires & Add Air</JumbText>
            <TexFild>If your tire has gone flat due to a nail or screw, we can come to your location to fill your tire with air or change the tire altogether.  If your tire has gone flat due to a nail or screw, we can come to your location to fill your tire with air or change the tire altogether. </TexFild>
          </Box>
        </TairMainBox>
      </Box>
      <FlatSecond />
    </>
  )
}

export default Flat
